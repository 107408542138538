import * as yup from 'yup';

export const emptyDefaultValues = {
    organizationId: '',
    image: [],
    title: '',
    description: '',
    price: '',
    duration: '',
    tripGrade: '',
    amenitiesProvided: '',
    destinations: '',
    itinerary: [
        {
            day: "",
            name: "",
            description: ""
        }
    ],
};

export const validationSchema = yup
    .object()
    .shape({
        // organizationId: yup
        //     .string()
        //     .required('Please select your organization.'),
        image: yup
            .array()
            .min(1, "Image is required.")
            .max(1, "Please limit the Image number to one "),
        title: yup
            .string()
            .required('Title is required.')
            .min(3, 'Must be more than 3 characters.'),
        description: yup
            .string()
            .required("Description is required.")
            .min(10, 'Must be more than 10 characters.'),
        price: yup
            .string()
            .required('Price is required.'),
        duration: yup
            .string()
            .required('Duration is required.'),
        tripGrade: yup
            .string()
            .required('Trip Grade is required.'),
    })
    .required();

export const normalizeTourPackages = (packages) => {
    const data = { ...packages };

    Object.keys(data).forEach((key) => {
        if (typeof emptyDefaultValues?.[key] === "undefined") {
            delete data[key];
        }
        else if (key === "organizationId") {
            delete data[key]
        }
    });

    return data;
};

export const setFormValues = (defaultValues, setValue) => {
    const { image } = defaultValues;
    return setValue("image", Array.isArray(image) ? [...image] : [image]);
};

export const normalizeTourPackagePayload = (payload) => {
    const data = {
        ...payload,
        amenitiesProvided: Array.isArray(payload.amenitiesProvided) ? payload.amenitiesProvided : payload.amenitiesProvided.split(", "),
        destinations: Array.isArray(payload.destinations) ? payload.destinations : payload.destinations.split(", "),
    }

    return data;
}
