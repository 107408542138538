import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { getOrganizations } from "../../services/api/organization";
import Spinner from "../ui/Spinner";
import { ImageUploadField } from "../ui/ImageUploadField";
import {
  emptyDefaultValues,
  normalizeCampingPackagePayload,
  normalizeCampingPackages,
  setFormValues,
  validationSchema,
} from "./utils";

export const CampingPackagesForm = ({
  defaultValues = emptyDefaultValues,
  onSave = () => {},
  pageID,
}) => {
  const [organizations, setOrganizations] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    fields: image,
    append: appendImages,
    remove: removeImages,
  } = useFieldArray({
    control,
    name: "image",
  });

  const fetchAndSetOrganizations = async () => {
    const res = await getOrganizations();
    if (res.status === 200) {
      const data = res?.data?.data;
      setOrganizations(data);
    }
    return res;
  };

  const onSubmit = async (payload) => {
    try {
      const data = normalizeCampingPackagePayload(payload);
      reset();
      navigate("/camping-packages");

      if (payload.organizationId) {
        const id = payload.organizationId;
        return onSave(id, normalizeCampingPackages(data));
      }

      return onSave(normalizeCampingPackages(data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAndSetOrganizations();
  }, []);

  useEffect(() => {
    Object.keys(normalizeCampingPackages(defaultValues)).forEach((key) => {
      setValue(key, defaultValues[key]);
    });
    setFormValues(defaultValues, setValue);
  }, [defaultValues, setValue]);

  return (
    <form
      className="px-8 space-y-8 divide-y divide-gray-200"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Package
            </h3>
            <p className="max-w-2xl mt-1 text-sm text-gray-500">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>

          <div className="space-y-6 sm:space-y-5">
            {!!pageID ? (
              ""
            ) : (
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="organization"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Organization
                </label>

                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <select
                      id="organizationId"
                      name="organizationId"
                      autoComplete="off"
                      {...register("organizationId")}
                      className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option></option>
                      {organizations.map((organization) => {
                        return (
                          <option
                            key={organization._id}
                            value={organization._id}
                          >
                            {organization.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {errors?.organizationId && (
                    <p className="my-3 text-sm text-rose-500">
                      {errors.organizationId.message}
                    </p>
                  )}
                </div>
              </div>
            )}

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="title"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Title
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    autoComplete="off"
                    {...register("title")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.title && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.title.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Description
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <textarea
                  id="description"
                  name="description"
                  rows={3}
                  {...register("description")}
                  className="block w-full max-w-lg rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                />
                <p className="mt-2 text-sm text-gray-500">
                  Write a few sentences about your package.
                </p>
                {errors?.description && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.description.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="ratePerPerson"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Rate Per Person
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="number"
                    name="ratePerPerson"
                    id="ratePerPerson"
                    autoComplete="off"
                    {...register("ratePerPerson")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.ratePerPerson && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.ratePerPerson.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="duration"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Duration
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="number"
                    name="duration"
                    id="duration"
                    autoComplete="off"
                    {...register("duration")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.duration && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.duration.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="capacity"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Capacity
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="number"
                    name="capacity"
                    id="capacity"
                    autoComplete="off"
                    {...register("capacity")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.capacity && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.capacity.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="amenitiesProvided"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Amenities Provided
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="amenitiesProvided"
                    id="amenitiesProvided"
                    autoComplete="amenitiesProvided"
                    {...register("amenitiesProvided")}
                    placeholder="Amenity1, Amenity2, ..."
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Please write the amenities one by one, separating them with
                  comma ",".{" "}
                </p>
                {errors?.amenitiesProvided && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.amenitiesProvided.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="foodProvided"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Food Provided
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="foodProvided"
                    id="foodProvided"
                    autoComplete="foodProvided"
                    {...register("foodProvided")}
                    placeholder="Food1, Food2, ..."
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Please write the amenities one by one, separating them with
                  comma ",".{" "}
                </p>
                {errors?.foodProvided && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.foodProvided.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="gearProvided"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Gear Provided
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="gearProvided"
                    id="gearProvided"
                    autoComplete="gearProvided"
                    {...register("gearProvided")}
                    placeholder="Gear1, Gear2, ..."
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Please write the amenities one by one, separating them with
                  comma ",".{" "}
                </p>
                {errors?.gearProvided && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.gearProvided.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="serviceProvided"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Service Provided
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="serviceProvided"
                    id="serviceProvided"
                    autoComplete="serviceProvided"
                    {...register("serviceProvided")}
                    placeholder="Gear1, Gear2, ..."
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Please write the amenities one by one, separating them with
                  comma ",".{" "}
                </p>
                {errors?.serviceProvided && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.serviceProvided.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="transportationProvided"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Transport Provided
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="transportationProvided"
                    id="transportationProvided"
                    autoComplete="transportationProvided"
                    {...register("transportationProvided")}
                    placeholder="Gear1, Gear2, ..."
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Please write the amenities one by one, separating them with
                  comma ",".{" "}
                </p>
                {errors?.transportationProvided && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.transportationProvided.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="activitiesProvided"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Activity Provided
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="activitiesProvided"
                    id="activitiesProvided"
                    autoComplete="activitiesProvided"
                    {...register("activitiesProvided")}
                    placeholder="Gear1, Gear2, ..."
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Please write the amenities one by one, separating them with
                  comma ",".{" "}
                </p>
                {errors?.activitiesProvided && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.activitiesProvided.message}
                  </p>
                )}
              </div>
            </div>

            <ImageUploadField
              image={image}
              appendImages={appendImages}
              removeImages={removeImages}
              errors={errors}
            />
          </div>
        </div>

        <div className="pt-8 space-y-6 sm:space-y-5 sm:pt-10">
          <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Location Details
            </h3>
            <p className="max-w-2xl mt-1 text-sm text-gray-500">
              Use a permanent address where you are located.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="campsiteName"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Campsite Name
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="campsiteName"
                    id="campsiteName"
                    autoComplete="off"
                    {...register("campsiteName")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.campsiteName && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.campsiteName.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="province"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                State / Province
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <select
                  id="province"
                  name="province"
                  autoComplete="province"
                  {...register("province")}
                  className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option></option>
                  <option>Bagmati</option>
                  <option>Gandaki</option>
                  <option>Karnali</option>
                  <option>Madhesh</option>
                  <option>Lumbini</option>
                  <option>Koshi</option>
                  <option>Sudurpashchim</option>
                </select>
                {errors?.province && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.province.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="city"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                City
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    autoComplete="off"
                    {...register("city")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.city && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.city.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="area"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Area
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="area"
                    id="area"
                    autoComplete="off"
                    {...register("area")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.area && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.area.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="address"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Address
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="address"
                    id="address"
                    autoComplete="off"
                    {...register("address")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.address && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.address.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="acessibility"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Acessibility
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="acessibility"
                    id="acessibility"
                    autoComplete="off"
                    {...register("acessibility")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.acessibility && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.acessibility.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end gap-x-3">
          <button
            type="button"
            onClick={() => navigate("/tour-packages")}
            className="px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={isSubmitting}
            className="inline-flex justify-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {isSubmitting ? <Spinner /> : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
};
