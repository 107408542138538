import axios from "axios";
import { getToken, getTokenForMultiPart } from "./getToken";
import { normalizePayloadToFormData } from "../../utils/form";

const BASE_URL = process.env.REACT_APP_PUBLIC_API;
const headers = getToken();
const headersForMultiPart = getTokenForMultiPart();

export const getGears = async ({ page = 1, dateFilter = null }) => {
  try {
    const response = await axios.get(`${BASE_URL}/admin/gear/list`, {
      headers,
      params: {
        page,
        dateFilter,
      },
    });
    return response;
  } catch (error) {
    console.error("Error while calling get gears api:", error);
  }
};

export const createGear = async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/gear/create`,
      payload,
      {
        headers,
      }
    );

    if (response.data.data.id) {
      const id = response.data.data.id;
      const thumbnailFormData = normalizePayloadToFormData({
        thumbnail: payload.thumbnail,
      });
      const galleryFormData = normalizePayloadToFormData({
        images: payload.gallery,
      });

      if (payload.thumbnail) await uploadGearThumbnail(thumbnailFormData, id);
      if (payload.gallery) await uploadGearGallery(galleryFormData, id);
    }
  } catch (error) {
    console.error("Error while calling create gear api:", error);
  }
};

export const uploadGearThumbnail = async (payload, id) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/admin/gear/upload-image/${id}?uploadType=thumbnail`,
      payload,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling upload gear thumbnail api:", error);
  }
};

export const uploadGearGallery = async (payload, id) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/admin/gear/upload-image/${id}?uploadType=images`,
      payload,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling upload gear thumbnail api:", error);
  }
};

export const deleteGearImage = async (id, payload) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/admin/gear/remove-image/${id}`,
      payload,
      { headers }
    );
    return response;
  } catch (error) {
    console.error("Error while calling get delete Gear image api:", error);
  }
};

export const updateGear = async (id, payload) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/admin/gear/update/${id}`,
      payload,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    console.error("Error while calling update gear api:", error);
  }
};

export const getGear = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/admin/gear/${id}`);
    return response;
  } catch (error) {
    console.error("Error while calling get gear api:", error);
  }
};

export const deleteGear = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/gear/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error while calling delete gear api:", error);
  }
};
