import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { PointOfInterestForm } from "../../components/pointOfInterest/Form";
import {
  getSinglePointOfInterest,
  updatePointOfInterest,
} from "../../services/api/blogs";
import { normalizePointOfInterest } from "../../components/pointOfInterest/utils";

export const EditPointOfInterest = () => {
  const [pointOfInterest, setPointOfInterest] = useState([]);
  const { id } = useParams();

  const fetchAndSetPointOfInterest = async () => {
    const res = await getSinglePointOfInterest(id);
    if (res.status === 200) {
      const data = res?.data?.pointOfInterest;
      setPointOfInterest(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetPointOfInterest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PointOfInterestForm
      defaultValues={normalizePointOfInterest(pointOfInterest)}
      onSave={(payload) => updatePointOfInterest(id, payload)}
      updatePointOfInterest={true}
    />
  );
};
