import Cookies from "universal-cookie";

const cookies = new Cookies();
const token = cookies.get("token");

export const getToken = () => {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return headers
}

export const getTokenForMultiPart = () => {
  const headersForMultiPart = {
    "Content-Type": `multipart/form-data: boundary=add-random-characters`,
    authorization: `Bearer ${token}`,
  };

  return headersForMultiPart
}
