import { useFieldArray } from "react-hook-form";
import Dropzone from "react-dropzone";
import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { formatBytes, normalizePayloadToFormData } from "../../utils/form";

export const ImageUploadField = ({
  control,
  multipleUploadAllowed = true,
  name = "images",
  updateAction,
  id,
  errorMessage = "",
  onUpload,
  onDelete,
}) => {
  const {
    fields: galleryImages,
    append: appendGalleryImages,
    remove: removeGalleryImages,
    replace: replaceImage,
  } = useFieldArray({
    control,
    name,
  });

  const handleAppendImages = async (file) => {
    if (multipleUploadAllowed) {
      if (updateAction) {
        const galleryFormData = normalizePayloadToFormData({
          images: file,
        });
        await onUpload(galleryFormData, id);

        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
      return appendGalleryImages(file);
    }
    if (updateAction) {
      const thumbnailFormData = normalizePayloadToFormData({
        thumbnail: file,
      });
      await onUpload(thumbnailFormData, id);

      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
    return replaceImage(file);
  };

  const handleRemoveImages = async (imageName, link, index) => {
    if (link) {
      await onDelete(id, { imageName });
    }
    removeGalleryImages(index);

    return index;
  };

  return (
    <div>
      <Dropzone
        onDrop={(acceptedFiles) => {
          const file = acceptedFiles[0];
          handleAppendImages(file);
        }}
        multiple={false}
        accept={{
          "image/*": [
            ".png",
            ".gif",
            ".jpeg",
            ".jpg",
            ".webp",
            ".bmp",
            ".tiff",
          ],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              <p className="flex flex-col items-center px-4 py-12 text-center bg-white border border-gray-300 border-dashed rounded-md cursor-pointer hover:border-gray-400">
                <span className="mb-4 text-3xl">
                  <PhotoIcon height={24} />
                </span>

                <span className="text-sm font-light">
                  Upload a file or drag and drop
                </span>
              </p>
            </div>
            {errorMessage ? (
              <p className="mt-2 text-xs text-red-500">{errorMessage}</p>
            ) : null}
          </section>
        )}
      </Dropzone>

      <ul className="px-4 mt-4 list-none bg-white divide-y divide-gray-200 rounded-md shadow-sm">
        {!!Object.keys(galleryImages).length &&
          galleryImages.map((file, index) => {
            if (file?.originalName || file?.path || file?.name) {
              return (
                <li
                  key={`post-image-${file.id}`}
                  className="flex flex-wrap items-center justify-between gap-4 py-4"
                >
                  <div className="flex gap-1 items-top">
                    <span className="mr-2 text-xl">
                      <PhotoIcon height={20} />
                    </span>

                    <span className="text-sm break-all">
                      {file?.originalName || file?.path || file?.name}
                      {file.size ? " - " + formatBytes(file.size) : ""}
                    </span>
                  </div>

                  <button
                    className="p-1 text-xl rounded-full hover:bg-gray-200"
                    onClick={() => {
                      if (updateAction) {
                        return handleRemoveImages(
                          file.originalName,
                          file?.link,
                          index
                        );
                      }
                      return removeGalleryImages(index);
                    }}
                    type="button"
                  >
                    <XMarkIcon height={20} />
                  </button>
                </li>
              );
            }
            return null;
          })}
      </ul>
    </div>
  );
};
