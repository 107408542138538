import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import App from "../../App";
import { getMe } from "./authFetch";


export function AutoLogin(children) {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const cookies = new Cookies();
  const token = cookies.get("token");

  useEffect(() => {
    autologin();
  }, []);

  const autologin = () => {
    if (token) {
      return dispatch(getMe(token));
    }
    // User is authenticated, handle accordingly
    else return nav("/login"); // User is not authenticated, handle accordingly for login
  };

  return <App />;
}
