import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { SingleView } from "../../components/blogs/SingleView";
import { getSingleBlog } from "../../services/api/blogs";

export function SingleBlog() {
  const [blog, setBlog] = useState([]);
  const { id } = useParams();

  const fetchAndSetBlog = async () => {
    const res = await getSingleBlog(id);
    if (res.status === 200) {
      const data = res?.data?.blog;
      setBlog(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetBlog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SingleView blog={blog} />;
}
