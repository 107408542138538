import axios from "axios";
import { getToken, getTokenForMultiPart } from "./getToken";
import { normalizePayloadToFormData } from "../../utils/form";
import { normalizeUpdateBlogPayload } from "../../components/blogs/utils";
import { normalizeUpdatePOIPayload } from "../../components/pointOfInterest/utils";

const BASE_URL = process.env.REACT_APP_PUBLIC_API;
const headers = getToken();
const headersForMultiPart = getTokenForMultiPart();

export const getReviewRequests = async ({
    requestStatus = "All",
    page = 1,
    dateFilter = null,
    sortColumn = null,
    sortDirection = "asc",
  }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/review-requests/all-requests`, {
        headers,
        params: {
          requestStatus,
          page,
          dateFilter,
          sortColumn,
          sortDirection,
        },
      });
      return response;
    } catch (error) {
      console.error("Error while calling get blogs api:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  };