import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'

import { getTourPackages, deleteTourPackage } from '../../services/api/tourPackages';

export function List() {
    const [packages, setPackages] = useState([]);
    const navigate = useNavigate();

    const fetchAndSetPackages = async () => {
        const res = await getTourPackages();
        if (res.status === 200) {
            const data = res?.data;
            setPackages(data);
        }
        return res;
    };

    useEffect(() => {
        fetchAndSetPackages()
    }, [])

    const handleDelete = async (id) => {
        await deleteTourPackage(id);
        return fetchAndSetPackages();
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Tour Packages</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all the packages with their full details.
                    </p>
                </div>

                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        onClick={() => navigate("/tour-packages/form")}
                        className="block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Add Packages
                    </button>
                </div>
            </div>

            <div className="flow-root mt-8">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Title
                                    </th>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                        Description
                                    </th>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                        Price
                                    </th>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                        Amenities Provided
                                    </th>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                        Itenary
                                    </th>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                        Destinations
                                    </th>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                        Duration
                                    </th>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                        Trip Grade
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="divide-y divide-gray-200">
                                {!!Object.keys(packages).length && packages.data.map((pack) => {
                                    return (
                                        <tr key={pack._id}>
                                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                                                {pack.title}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pack.description}</td>
                                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pack.price}</td>
                                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pack.amenitiesProvided.join(', ')}</td>
                                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                {pack.itinerary.map((i) => (
                                                    <div key={i.name}>
                                                        <span>{i.day} - {i.name}</span>
                                                        <br />
                                                        <span>{i.description}</span>
                                                        <br />
                                                    </div>
                                                ))}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pack.destinations.join(', ')}</td>
                                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pack.duration} days</td>
                                            <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">{pack.tripGrade}</td>
                                            <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                                                <button
                                                    type="button"
                                                    title='Edit'
                                                    onClick={() => navigate(`/tour-packages/${pack._id}`)}
                                                    className="p-1 mr-4 text-indigo-600 rounded-full hover:bg-gray-200"
                                                >
                                                    <PencilSquareIcon className='w-5 h-5' />
                                                    <span className="sr-only">, {pack.title}</span>
                                                </button>

                                                <button
                                                    type="button"
                                                    title='Delete'
                                                    onClick={() => handleDelete(`${pack._id}`)}
                                                    className="p-1 text-red-600 rounded-full hover:bg-gray-200"
                                                >
                                                    <TrashIcon className='w-5 h-5' />
                                                    <span className="sr-only">, {pack.title}</span>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}