import * as yup from 'yup';

export const emptyDefaultValues = {
    organizationId: '',
    image: [],
    title: '',
    description: '',
    ratePerPerson: '',
    duration: '',
    amenitiesProvided: '',
    foodProvided: '',
    gearProvided: '',
    serviceProvided: '',
    transportationProvided: '',
    activitiesProvided: '',
    location: {
        campsiteName: '',
        province: '',
        city: '',
        area: '',
        address: '',
        acessibility: '',
    },
    capacity: '',
};

export const validationSchema = yup
    .object()
    .shape({
        // organizationId: yup
        //     .string()
        //     .required('Please select your organization.'),
        image: yup
            .array()
            .min(1, "Image is required.")
            .max(1, "Please limit the Image number to one "),
        title: yup
            .string()
            .required('Title is required.')
            .min(3, 'Must be more than 3 characters.'),
        description: yup
            .string()
            .required("Description is required.")
            .min(10, 'Must be more than 10 characters.'),
        ratePerPerson: yup
            .string()
            .required('Rate Per Person is required.'),
        duration: yup
            .string()
            .required('Duration is required.'),
        campsiteName: yup
            .string()
            .required('Campsite Name is required.'),
        province: yup
            .string()
            .required('Province is required.'),
        area: yup
            .string()
            .required('Area is required.'),
        city: yup
            .string()
            .required('city is required.'),
        address: yup
            .string()
            .required('address is required.'),
        acessibility: yup
            .string()
            .required('acessibility is required.'),
        capacity: yup
            .string()
            .required('capacity is required.'),
    })
    .required();

export const normalizeCampingPackages = (packages) => {
    const data = { ...packages };

    Object.keys(data).forEach((key) => {
        if (typeof emptyDefaultValues?.[key] === "undefined") {
            delete data[key];
        }
        else if (key === "organizationId") {
            delete data[key]
        }
    });

    return data;
};

export const setFormValues = (defaultValues, setValue) => {
    const { location = {}, image } = defaultValues;
    const { address, area, city, province, campsiteName, acessibility } = location;

    return (
        setValue("campsiteName", campsiteName),
        setValue("province", province),
        setValue("city", city),
        setValue("area", area),
        setValue("address", address),
        setValue("acessibility", acessibility),
        setValue("image", Array.isArray(image) ? [...image] : [image])
    )
};

export const normalizeCampingPackagePayload = (payload) => {
    let data = {
        ...payload,
        amenitiesProvided: Array.isArray(payload.amenitiesProvided) ? payload.amenitiesProvided : payload.amenitiesProvided.split(", "),
        foodProvided: Array.isArray(payload.foodProvided) ? payload.foodProvided : payload.foodProvided.split(", "),
        gearProvided: Array.isArray(payload.gearProvided) ? payload.gearProvided : payload.gearProvided.split(", "),
        serviceProvided: Array.isArray(payload.serviceProvided) ? payload.serviceProvided : payload.serviceProvided.split(", "),
        transportationProvided: Array.isArray(payload.transportationProvided) ? payload.transportationProvided : payload.transportationProvided.split(", "),
        activitiesProvided: Array.isArray(payload.activitiesProvided) ? payload.activitiesProvided : payload.activitiesProvided.split(", "),
        location: {
            campsiteName: payload.campsiteName,
            province: payload.province,
            city: payload.city,
            area: payload.area,
            address: payload.address,
            acessibility: payload.acessibility
        }
    }

    return data;
}
