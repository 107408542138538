// import * as yup from "yup";

export const emptyDefaultValues = {
  title: "",
  poiType: "",
  length: "",
  trailGrade: "",
  roadTypes: [], // Add roadTypes array
  description: "",
  image: "",
  altitude: "",
  gallery: [],
};

export const editPOIDefaultValues = {
  id: "",
  title: "",
  poiType: "",
  length: "",
  trailGrade: "",
  roadTypes: [], // Add roadTypes array
  description: "",
  image: "",
  altitude: "",
  gallery: [],
};

export const normalizePointOfInterest = (pointOfInterest) => {
  const data = { ...pointOfInterest };

  Object.keys(data).forEach((key) => {
    if (typeof editPOIDefaultValues?.[key] === "undefined") {
      delete data[key];
    }
  });

  return data;
};

export const editPOIPayloadDefaultValues = {
  pointOfInterestId: "",
  title: "",
  poiType: "",
  length: "",
  trailGrade: "",
  roadTypes: [],
  description: "",
  altitude: "",
};

export const normalizeUpdatePOIPayload = (id, pointOfInterest) => {
  const data = { ...pointOfInterest, pointOfInterestId: id };

  Object.keys(data).forEach((key) => {
    if (typeof editPOIPayloadDefaultValues?.[key] === "undefined") {
      delete data[key];
    }
  });

  return data;
};

export const setFormValues = (defaultValues, setValue) => {
  const { image, gallery, roadTypes } = defaultValues; // Extract roadTypes
  setValue("gallery", Array.isArray(gallery) ? [...gallery] : [gallery]);
  setValue(
    "roadTypes",
    Array.isArray(roadTypes) ? [...roadTypes] : [roadTypes]
  ); // Set roadTypes array
  if (image !== null) {
    setValue("thumbnail", [image]);
  }
};
