import axios from "axios";
import { getToken, getTokenForMultiPart } from "./getToken";
import { normalizePayloadToFormData } from "../../utils/form";

const BASE_URL = process.env.REACT_APP_PUBLIC_API;
const headers = getToken();
const headersForMultiPart = getTokenForMultiPart();

export const getTourPackages = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/packages`);
    return response;
  } catch (error) {
    console.error("Error while calling get packages api:", error);
  }
};

export const createTourPackage = async (id, payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/organization/${id}/packages`,
      payload,
      { headers }
    );
    const packageId = response.data.data._id;

    const uploadResponse = await uploadImageToTourPackage(packageId, payload);
    return uploadResponse;
  } catch (error) {
    console.error("Error while calling create package api:", error);
  }
};

export const uploadImageToTourPackage = async (packageId, payload) => {
  try {
    const formData = normalizePayloadToFormData({ file: payload.image });

    const response = await axios.post(
      `${BASE_URL}/packages/upload/${packageId}`,
      formData,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling upload image to package api:", error);
  }
};

export const getTourPackage = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/packages/${id}`);
    return response;
  } catch (error) {
    console.error("Error while calling get package api:", error);
  }
};

export const updateTourPackage = async (id, payload) => {
  try {
    const response = await axios.put(`${BASE_URL}/packages/${id}`, payload, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error while calling update package api:", error);
  }
};

export const deleteTourPackage = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/packages/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error while calling delete package api:", error);
  }
};
