import React from "react";

export const SingleView = ({ blog }) => {
  return (
    <div>
      <div className="flex flex-col gap-6">
        <div className="container p-4 mx-auto bg-gray-100 rounded-md sm:p-6">
          <div claclassNamess="mb-4 md:mb-0 w-full mx-auto relative">
            <div className="px-0">
              <h1 className="text-4xl font-semibold leading-tight text-gray-800">
                {blog.title}
              </h1>
              <p className="inline-flex items-center justify-center py-2 mb-2 text-green-700">
                07 February, 2024
              </p>

              <div>
                <p class="text-lg font-semibold text-gray-700">
                  Trail Head: {blog.trailHead}
                </p>
              </div>

              <div>
                <p class="text-lg font-semibold text-gray-700">
                  Trail End: {blog.trailEnd}
                </p>
              </div>

              <div>
                <p class="text-lg font-semibold text-gray-700">
                  Route Type: {blog.routeType}
                </p>
              </div>

              <div>
                <p class="text-lg font-semibold text-gray-700">
                  Total Ascend: {blog.totalAscend}
                </p>
              </div>

              <div>
                <p class="text-lg font-semibold text-gray-700">
                  Total Descend: {blog.totalDescend}
                </p>
              </div>

              <div>
                <p class="text-lg font-semibold text-gray-700">
                  Fintess Level: {blog.fitnessLevel}
                </p>
              </div>

              <div>
                <p class="text-lg font-semibold text-gray-700">
                  Major Attraction : {blog.majorAttraction}
                </p>
              </div>

              <div>
                <p class="text-lg font-semibold text-gray-700 pb-6">
                  Over All Trail Grade: {blog.overallTrailGrade}
                </p>
              </div>

              <div class="grid gap-6 mb-6 md:grid-cols-2 xl:grid-cols-4">
                <div class="flex items-center p-4 bg-white rounded-lg shadow-xs">
                  <div class="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                    </svg>
                  </div>
                  <div>
                    <p class="mb-2 text-sm font-medium text-gray-600">
                      Altitude
                    </p>
                    <p class="text-lg font-semibold text-gray-700">
                      {blog.altitude}
                    </p>
                  </div>
                </div>
                <div class="flex items-center p-4 bg-white rounded-lg shadow-xs">
                  <div class="p-3 mr-4 text-green-500 bg-green-100 rounded-full">
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                    </svg>
                  </div>
                  <div>
                    <p class="mb-2 text-sm font-medium text-gray-600">
                      Total Hour
                    </p>
                    <p class="text-lg font-semibold text-gray-700">
                      {blog.time}
                    </p>
                  </div>
                </div>
                <div class="flex items-center p-4 bg-white rounded-lg shadow-xs">
                  <div class="p-3 mr-4 text-red-500 bg-red-100 rounded-full">
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                    </svg>
                  </div>
                  <div>
                    <p class="mb-2 text-sm font-medium text-gray-600">
                      Total Km
                    </p>
                    <p class="text-lg font-semibold text-gray-700">
                      {blog.distance}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={blog.image?.link}
                  alt="blog-thumbnail"
                  className="w-full mb-6 object-cover lg:rounded h-[500px]"
                />
              </div>

              <div className="w-full mb-12 text-lg leading-relaxed text-gray-700 break-words lg:px-0">
                <div dangerouslySetInnerHTML={{ __html: blog.description }} />
              </div>

              <div className="flex flex-col gap-6 mb-12">
                <h2 className="text-3xl font-bold">Blog Gallery</h2>
                <div className="flex gap-6">
                  {blog.gallery &&
                    blog.gallery.map(({ link }) => {
                      return (
                        <img
                          key={link}
                          src={link}
                          alt="blog-gallery"
                          className="rounded-lg shadow-md h-96 w-96 hover:opacity-75"
                        />
                      );
                    })}
                </div>
              </div>

              <div className="flex flex-col gap-6">
                <h2 className="mb-0 text-3xl font-bold">Point Of Interests</h2>

                {blog.pointOfInterestRelations &&
                  blog.pointOfInterestRelations.map(
                    (
                      {
                        id,
                        altitude,
                        totalHour,
                        totalKm,
                        pointOfInterest: { title, description, image, gallery },
                      },
                      index
                    ) => {
                      return (
                        <div key={id}>
                          <div className="pb-4 text-xl font-semibold leading-tight text-gray-800">
                            #{index + 1} {title}
                          </div>

                          <div className="flex gap-6">
                            <img
                              src={image?.link}
                              alt="blog-thumbnail"
                              className="w-full object-cover lg:rounded h-[400px] mb-6"
                            />
                          </div>

                          <div class="grid gap-6 mb-6 md:grid-cols-2 xl:grid-cols-4">
                            <div class="flex items-center p-4 bg-white rounded-lg shadow-xs">
                              <div class="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                                <svg
                                  class="w-5 h-5"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                                </svg>
                              </div>
                              <div>
                                <p class="mb-2 text-sm font-medium text-gray-600">
                                  Altitude
                                </p>
                                <p class="text-lg font-semibold text-gray-700">
                                  {altitude}
                                </p>
                              </div>
                            </div>
                            <div class="flex items-center p-4 bg-white rounded-lg shadow-xs">
                              <div class="p-3 mr-4 text-green-500 bg-green-100 rounded-full">
                                <svg
                                  class="w-5 h-5"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                                </svg>
                              </div>
                              <div>
                                <p class="mb-2 text-sm font-medium text-gray-600">
                                  Total Hour
                                </p>
                                <p class="text-lg font-semibold text-gray-700">
                                  {totalHour}
                                </p>
                              </div>
                            </div>
                            <div class="flex items-center p-4 bg-white rounded-lg shadow-xs">
                              <div class="p-3 mr-4 text-red-500 bg-red-100 rounded-full">
                                <svg
                                  class="w-5 h-5"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                                </svg>
                              </div>
                              <div>
                                <p class="mb-2 text-sm font-medium text-gray-600">
                                  Total Km
                                </p>
                                <p class="text-lg font-semibold text-gray-700">
                                  {totalKm}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="w-full mb-12 text-lg leading-relaxed text-gray-700 break-words lg:px-0">
                            <div
                              dangerouslySetInnerHTML={{ __html: description }}
                            />
                          </div>

                          <div className="flex flex-col gap-6 mb-6">
                            <h2 className="text-3xl font-bold">
                              Point of interest Gallery
                            </h2>
                            <div className="flex gap-6">
                              {gallery &&
                                gallery.map(({ link }) => {
                                  return (
                                    <img
                                      key={link}
                                      src={link}
                                      alt="blog-gallery"
                                      className="rounded-lg shadow-md h-96 w-96 hover:opacity-75"
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
