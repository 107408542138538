import axios from "axios";
import { getToken, getTokenForMultiPart } from "./getToken";
import { normalizePayloadToFormData } from "../../utils/form";
import { normalizeUpdateBlogPayload } from "../../components/blogs/utils";
import { normalizeUpdatePOIPayload } from "../../components/pointOfInterest/utils";

const BASE_URL = process.env.REACT_APP_PUBLIC_API;
const headers = getToken();
const headersForMultiPart = getTokenForMultiPart();

// PointOfInterest Services
export const createPointOfInterest = async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/blog/create-pointOfInterest`,
      payload,
      { headers }
    );

    if (response.data.pointOfInterest.id) {
      const id = response.data.pointOfInterest.id;
      const thumbnailFormData = normalizePayloadToFormData({
        thumbnail: payload.thumbnail,
      });
      const galleryFormData = normalizePayloadToFormData({
        images: payload.gallery,
      });

      if (payload.thumbnail)
        await uploadPointOfInterestThumbnail(thumbnailFormData, id);
      if (payload.gallery)
        await uploadPointOfInterestGallery(galleryFormData, id);
    }

    return response;
  } catch (error) {
    console.error("Error while calling create point of interest api:", error);
  }
};

export const updatePointOfInterest = async (id, payload) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/blog/update-pointOfInterest`,
      normalizeUpdatePOIPayload(id, payload),
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    console.error("Error while calling get single blog api:", error);
  }
};

export const uploadPointOfInterestThumbnail = async (payload, id) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/blog/pointOfInterest/upload-image/${id}?uploadType=thumbnail`,
      payload,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling create blog api:", error);
  }
};

export const uploadPointOfInterestGallery = async (payload, id) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/blog/pointOfInterest/upload-image/${id}?uploadType=images`,
      payload,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling create blog api:", error);
  }
};

export const deletePointOfInterestImage = async (id, payload) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/blog/pointOfInterest/remove-image/${id}`,
      payload,
      { headers }
    );
    return response;
  } catch (error) {
    console.error(
      "Error while calling get delete Point Of Interest image api:",
      error
    );
  }
};

export const getPointOfInterestsForCampsiteForm = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/blog/pointOfInterest/list`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error while calling get point of interests api:", error);
  }
};

export const getPointOfInterests = async ({
  page = 1,
  dateFilter = null,
  sortColumn = null,
  sortDirection = "asc",
}) => {
  try {
    const response = await axios.get(`${BASE_URL}/blog/pointOfInterest/list`, {
      headers,
      params: {
        page,
        dateFilter,
        sortColumn,
        sortDirection,
      },
    });
    return response;
  } catch (error) {
    console.error("Error while calling get point of interests api:", error);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const getSinglePointOfInterest = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/blog/pointOfInterest/${id}`);
    return response;
  } catch (error) {
    console.error(
      "Error while calling get single point of interest api:",
      error
    );
  }
};

// Blog Services
export const createBlog = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/blog/create-blog`, payload, {
      headers,
    });

    if (response.data.blog.id) {
      const id = response.data.blog.id;
      const thumbnailFormData = normalizePayloadToFormData({
        thumbnail: payload.thumbnail,
      });

      const galleryFormData = normalizePayloadToFormData({
        images: payload.gallery,
      });

      if (payload.thumbnail) await uploadBlogThumbnail(thumbnailFormData, id);
      if (payload.gallery) await uploadBlogGallery(galleryFormData, id);
    }

    return response;
  } catch (error) {
    console.error("Error while calling create blog api:", error);
  }
};

export const updateBlog = async (id, payload) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/blog/update-blog`,
      normalizeUpdateBlogPayload(id, payload),
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    console.error("Error while calling get single blog api:", error);
  }
};

export const uploadBlogThumbnail = async (payload, id) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/blog/upload-image/${id}?uploadType=thumbnail`,
      payload,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling create blog api:", error);
  }
};

export const uploadBlogGallery = async (payload, id) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/blog/upload-image/${id}?uploadType=images`,
      payload,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling create blog api:", error);
  }
};

export const deleteBlogImage = async (id, payload) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/blog/remove-image/${id}`,
      payload,
      { headers }
    );
    return response;
  } catch (error) {
    console.error("Error while calling get delete blog image api:", error);
  }
};

export const getBlogs = async ({
  blogStatus = "All",
  page = 1,
  dateFilter = null,
  sortColumn = null,
  sortDirection = "asc",
}) => {
  try {
    const response = await axios.get(`${BASE_URL}/blog/all-blogs`, {
      headers,
      params: {
        blogStatus,
        page,
        dateFilter,
        sortColumn,
        sortDirection,
      },
    });
    return response;
  } catch (error) {
    console.error("Error while calling get blogs api:", error);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const getSingleBlog = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/blog/list/${id}`);
    return response;
  } catch (error) {
    console.error("Error while calling get single blog api:", error);
  }
};
