// import * as yup from "yup";

export const emptyDefaultValues = {
  title: "",
  description: "",
  image: "",
  distance: "",
  time: "",
  altitude: "",
  trailHead: "",
  trailEnd: "",
  routeType: "",
  totalAscend: "",
  totalDescend: "",
  fitnessLevel: "",
  majorAttraction: "",
  overallTrailGrade: "",
  gallery: [],
  pointOfInterests: [],
};

export const editBlogDefaultValues = {
  id: "",
  title: "",
  description: "",
  distance: "",
  time: "",
  altitude: "",
  image: "",
  trailHead: "",
  trailEnd: "",
  routeType: "",
  totalAscend: "",
  totalDescend: "",
  fitnessLevel: "",
  majorAttraction: "",
  overallTrailGrade: "",
  gallery: [],
  pointOfInterestRelations: [],
  blogStatus: "",
};

export const normalizeEditBlog = (blog) => {
  const data = { ...blog };

  Object.keys(data).forEach((key) => {
    if (typeof editBlogDefaultValues?.[key] === "undefined") {
      delete data[key];
    }
  });

  return data;
};

export const editBlogPayloadDefaultValues = {
  blogId: "",
  title: "",
  description: "",
  distance: "",
  time: "",
  altitude: "",
  trailHead: "",
  trailEnd: "",
  routeType: "",
  totalAscend: "",
  totalDescend: "",
  fitnessLevel: "",
  majorAttraction: "",
  overallTrailGrade: "",
  pointOfInterests: [
    { pointOfInterestId: "", totalHour: "", totalKm: "", altitude: "" },
  ],
  blogStatus: "",
};

export const normalizeUpdateBlogPayload = (id, blog) => {
  const data = { ...blog, blogId: id };

  Object.keys(data).forEach((key) => {
    if (key === "pointOfInterests" && Array.isArray(data[key])) {
      data[key] = data[key].map((point) => {
        const normalizedPoint = {
          ...editBlogPayloadDefaultValues.pointOfInterests[0],
        };
        Object.keys(point).forEach((pointKey) => {
          if (typeof normalizedPoint[pointKey] === "undefined") {
            delete point[pointKey];
          }
        });
        return point;
      });
    } else if (typeof editBlogPayloadDefaultValues?.[key] === "undefined") {
      delete data[key];
    }
  });

  return data;
};

export const setFormValues = (defaultValues, setValue) => {
  const { image, gallery, pointOfInterestRelations } = defaultValues;
  setValue("gallery", Array.isArray(gallery) ? [...gallery] : [gallery]);
  setValue(
    "pointOfInterests",
    Array.isArray(pointOfInterestRelations)
      ? [...pointOfInterestRelations]
      : [pointOfInterestRelations]
  );
  if (image !== null) {
    setValue("thumbnail", [image]);
  }
};

// export const validationSchema = yup.object({
//   image: yup
//     .array()
//     .min(1, "Image is required.")
//     .max(1, "Please limit the Image number to one "),
//   name: yup
//     .string()
//     .required('Name is required.')
//     .min(3, 'Must be more than 3 characters.'),
//   description: yup
//     .string()
//     .required("Description is required.")
//     .min(10, 'Must be more than 10 characters.'),
//   phone: yup
//     .string()
//     .matches(/^[0-9]{10}$/, 'Invalid phone number.')
//     .required('Phone number is required'),
//   email: yup
//     .string()
//     .email('Invalid email')
//     .required('Email is required.'),
//   address: yup.string().required("Address is required."),
// });

// export const normalizeOrganization = (organization) => {
//   const data = { ...organization };

//   Object.keys(data).forEach((key) => {
//     if (typeof emptyDefaultValues?.[key] === "undefined") {
//       delete data[key];
//     }
//   });

//   return data;
// };
