import * as yup from "yup";

export const emptyDefaultValues = {
  name: "",
  description: "",
  quantity: "",
  realPrice: "",
  discountedPrice: "",
  image: "",
  gallery: [],
};

export const editGearDefaultValues = {
  id: "",
  name: "",
  description: "",
  quantity: "",
  realPrice: "",
  discountedPrice: "",
  image: "",
  gallery: [],
};

export const normalizeEditGear = (gear) => {
  const data = { ...gear };

  Object.keys(data).forEach((key) => {
    if (typeof editGearDefaultValues?.[key] === "undefined") {
      delete data[key];
    }
  });

  return data;
};

export const editGearPayloadDefaultValues = {
  gearId: "",
  name: "",
  description: "",
  quantity: "",
  realPrice: "",
  discountedPrice: "",
  image: "",
  gallery: [],
};

export const normalizeUpdateGearPayload = (id, gear) => {
  const data = { ...gear, gearId: id };

  Object.keys(data).forEach((key) => {
    if (typeof editGearPayloadDefaultValues?.[key] === "undefined") {
      delete data[key];
    }
  });

  return data;
};

export const setFormValues = (defaultValues, setValue) => {
  const { image, gallery } = defaultValues;
  setValue("gallery", Array.isArray(gallery) ? [...gallery] : [gallery]);
  if (image !== null) {
    setValue("thumbnail", [image]);
  }
};
