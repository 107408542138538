import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { GearForm } from "../../components/gear/Form";
import { normalizeEditGear } from "../../components/gear/utils";
import { updateGear, getGear } from "../../services/api/gear";

export const EditGear = () => {
  const [gear, setGear] = useState([]);
  const { id } = useParams();

  const fetchAndSetGear = async () => {
    const res = await getGear(id);
    if (res.status === 200) {
      const data = res?.data?.data;
      setGear(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetGear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GearForm
      defaultValues={normalizeEditGear(gear)}
      onSave={(payload) => updateGear(id, payload)}
      updateGear={true}
    />
  );
};
