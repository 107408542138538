import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

import { loginfetch, logoutfetch, getMe } from "./authFetch";

const cookies = new Cookies();

const initialState = {
  isLoggedIn: false,
  jwt: null,
  id: "",
  email: "",
  name: "",
  role: "",
  status: "idle", //idle,loading,succeeded,failed
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login() {
      console.log("login called");
    }
  },
  extraReducers(builder) {
    builder
      //login
      .addCase(loginfetch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginfetch.fulfilled, (state, action) => {
        state.status = "succeeded";

        //state management
        state.isLoggedIn = true;
        state.jwt = action.payload;
        state.status = "success";

        const cookies = new Cookies();

        //Set cookies
        cookies.set("token", action.payload, {
          expires: new Date(token.exp * 1000 * 60 * 60 * 24 * 30),
        });
      })
      .addCase(loginfetch.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //logout
      .addCase(logoutfetch.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.jwt = null;
        state.id = "";
        state.email = "";
        state.name = "";
        state.status = "idle";
        state.error = null;
        window.location.reload();
      })
      .addCase(logoutfetch.rejected, (state) => {
        state.status = "failed";
        state.error = "logout failed";
      })

      //getme
      .addCase(getMe.fulfilled, (state, action) => {
        const { email, name, id, role } = action.payload;
        state.isLoggedIn = true;
        state.id = id;
        state.name = name;
        state.email = email;
        state.status = "success";
        state.role = role;
        //token set at last
        const token = cookies.get("token");
        state.jwt = token;
      })
      .addCase(getMe.rejected, (state, action) => {
        state.error = action.error.message;
        console.log(state.error);
      });
  },
});

export const isLoggedIn = (state) => state.auth.isLoggedIn;
export const token = (state) => state.auth.jwt;
export const name = (state) => state.auth.name;
export const email = (state) => state.auth.email;
export const status = (state) => state.auth.status;
export const err = (state) => state.auth.error;

export const { login, logout, register, getToken, resetState } =
  authSlice.actions;
export default authSlice.reducer;
