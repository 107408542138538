import React, { useEffect } from 'react'
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";

import Spinner from '../ui/Spinner';
import { ImageUploadField } from '../ui/ImageUploadField';
import { emptyDefaultValues, setFormValues, validationSchema } from './utils'

export const OrganizationForm = ({ defaultValues = emptyDefaultValues, onSave = () => { } }) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    fields: image,
    append: appendImages,
    remove: removeImages,
  } = useFieldArray({
    control,
    name: "image",
  });

  const onSubmit = async (payload) => {
    try {
      reset()
      navigate('/organization')
      return onSave(payload);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    Object.keys(defaultValues).forEach(key => {
      setValue(key, defaultValues[key]);
    });
    
    setFormValues(defaultValues, setValue)
  }, [defaultValues, setValue]);
  
  return (
    <div>
      <form
        className="px-8 space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Organization
              </h3>
              <p className="max-w-2xl mt-1 text-sm text-gray-500">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>

            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      autoComplete="name"
                      {...register("name")}
                      className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {errors?.name && (
                    <p className="my-3 text-sm text-rose-500">
                      {errors.name.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
            Description
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <textarea
              id="description"
              name="description"
              rows={3}
              {...register("description")}
              className="block w-full max-w-lg rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
            />
            <p className="mt-2 text-sm text-gray-500">Write a few sentences about your campsite.</p>
            {errors?.description && (
              <p className='my-3 text-sm text-rose-500'>
                {errors.description.message}
              </p>
            )}
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
            Phone
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="flex max-w-lg rounded-md shadow-sm">
              <input
                type="number"
                name="phone"
                id="phone"
                autoComplete="phone"
                {...register("phone")}
                className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errors?.phone && (
              <p className='my-3 text-sm text-rose-500'>
                {errors.phone.message}
              </p>
            )}
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
            Email
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="flex max-w-lg rounded-md shadow-sm">
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                {...register("email")}
                className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errors?.email && (
              <p className='my-3 text-sm text-rose-500'>
                {errors.email.message}
              </p>
            )}
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
            Address
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <input
              type="text"
              name="address"
              id="address"
              autoComplete="address"
              {...register("address")}
              className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            {errors?.address && (
              <p className='my-3 text-sm text-rose-500'>
                {errors.address.message}
              </p>
            )}
          </div>


        </div>
        <ImageUploadField image={image} appendImages={appendImages} removeImages={removeImages} errors={errors} />


        <div className="pt-5">
          <div className="flex justify-end gap-x-3">
            <button
              type="button"
              onClick={() => navigate('/organization')}
              className="px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex justify-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {isSubmitting ? <Spinner /> : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};