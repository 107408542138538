import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getSinglePointOfInterest } from "../../services/api/blogs";
import { SingleView } from "../../components/pointOfInterest/SingleView";

export function SinglePointOfInterest() {
  const [pointOfInterest, setPointOfInterest] = useState([]);
  const { id } = useParams();

  const fetchAndSetPointOfInterest = async () => {
    const res = await getSinglePointOfInterest(id);
    if (res.status === 200) {
      const data = res?.data?.pointOfInterest;
      setPointOfInterest(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetPointOfInterest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SingleView pointOfInterest={pointOfInterest} />;
}
