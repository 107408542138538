import * as yup from "yup";

export const amenitiesList = ["SwimmingPool", "PortableToilet"];

export const activitiesList = ["GuidedHiking", "Meditation"];

export const naturalFeaturesList = ["River", "Mountain"];

export const emptyDefaultValues = {
  thumbnail: "",
  title: "",
  description: "",
  price: "",
  category: "",
  siteOwnership: "",
  activities: [],
  availableAmenities: [],
  availableSeats: 0,
  naturalFeatures: [],
  location: {
    campsiteName: "",
    province: "",
    city: "",
    area: "",
    address: "",
  },
};

export const editCampsiteDefaultValues = {
  id: "",
  title: "",
  description: "",
  image: "",
  gallery: [],
  activities: [],
  availableAmenities: [],
  naturalFeatures: [],
  price: "",
  type: "",
  category: "",
  location: {
    campsiteName: "",
    province: "",
    city: "",
    area: "",
    address: "",
  },
  siteOwnership: "",
  availableSeats: 0,
};

export const normalizeEditCampsite = (campsite) => {
  const data = { ...campsite };

  // Ensure location is an object, even if null or undefined
  if (!data.location) {
    data.location = { ...editCampsiteDefaultValues.location };
  } else {
    Object.keys(editCampsiteDefaultValues.location).forEach((key) => {
      if (typeof data.location[key] === "undefined" || data.location[key] === null) {
        data.location[key] = editCampsiteDefaultValues.location[key];
      }
    });
  }

  // Normalize other fields
  Object.keys(editCampsiteDefaultValues).forEach((key) => {
    if (typeof data[key] === "undefined" || data[key] === null) {
      data[key] = editCampsiteDefaultValues[key];
    }
  });

  return data;
};


export const validationSchema = yup
  .object()
  .shape({
    image: yup
      .array()
      .min(1, "Image is required.")
      .max(1, "Please limit the Image number to one "),
    title: yup
      .string()
      .required("Title is required.")
      .min(3, "Must be more than 3 characters."),
    description: yup
      .string()
      .required("Description is required.")
      .min(10, "Must be more than 10 characters."),
    price: yup.string().required("Price is required."),
    category: yup.string().required("Category is required."),
    siteOwnership: yup.string().required("Site Ownership is required."),
    address: yup.string().required("Address is required."),
    city: yup.string().required("City is required."),
    area: yup.string().required("Area is required."),
    province: yup.string().required("Province is required."),
    availableSeats: yup.number().required("Available Seats is required."),
  })
  .required();

export const normalizeCampsite = (campsite) => {
  const data = { ...campsite };

  Object.keys(data).forEach((key) => {
    if (typeof emptyDefaultValues?.[key] === "undefined") {
      delete data[key];
    }
  });

  return data;
};

export const setFormValues = (defaultValues, setValue) => {
  const { image, gallery, location = {} } = defaultValues;
  const { address, area, city, province, campsiteName } = location;

  return (
    setValue("campsiteName", campsiteName),
    setValue("province", province),
    setValue("city", city),
    setValue("area", area),
    setValue("address", address),
    setValue("gallery", Array.isArray(gallery) ? [...gallery] : [gallery]),
    image !== null && setValue("thumbnail", [image])
  );
};

export const normalizeCampsitePayload = (payload) => {
  const data = {
    ...payload,
    numberOfPerson: payload.availableSeats.toString(),
    activities: Array.isArray(payload.activities)
      ? payload.activities
      : payload.activities.split(", "),
    availableAmenities: Array.isArray(payload.availableAmenities)
      ? payload.availableAmenities
      : payload.availableAmenities.split(", "),
    naturalFeatures: Array.isArray(payload.naturalFeatures)
      ? payload.naturalFeatures
      : payload.naturalFeatures.split(", "),
    location: {
      province: payload.province,
      city: payload.city,
      area: payload.area,
      address: payload.address,
    },
  };

  return data;
};
