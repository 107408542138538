import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { BlogForm } from '../../components/blogs/Form'
import { getSingleBlog, updateBlog } from '../../services/api/blogs'
import { normalizeEditBlog } from '../../components/blogs/utils'

export const EditBlog = () => {
    const [blog, setBlog] = useState([]);
    const { id } = useParams();

    const fetchAndSetBlog = async () => {
        const res = await getSingleBlog(id);
        if (res.status === 200) {
            const data = res?.data?.blog;
            setBlog(data);
        }
        return res;
    };

    useEffect(() => {
        fetchAndSetBlog()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <BlogForm defaultValues={normalizeEditBlog(blog)} onSave={(payload) => updateBlog(id, payload)} updateBlog={true}/>
    )
}