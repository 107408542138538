import axios from "axios";
import { getToken, getTokenForMultiPart } from "./getToken";
import { normalizePayloadToFormData } from "../../utils/form";

const BASE_URL = process.env.REACT_APP_PUBLIC_API;
const headers = getToken();
const headersForMultiPart = getTokenForMultiPart();

export const getCampingPackages = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/packages-camping`);
    return response;
  } catch (error) {
    console.error("Error while calling get packages api:", error);
  }
};

export const createCampingPackage = async (id, payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/organization/${id}/packages-camping`,
      payload,
      { headers }
    );
    const packageId = response.data.data._id;

    const uploadResponse = await uploadImageToCampingPackage(
      packageId,
      payload
    );
    return uploadResponse;
  } catch (error) {
    console.error("Error while calling create package api:", error);
  }
};

export const uploadImageToCampingPackage = async (id, payload) => {
  try {
    const formData = normalizePayloadToFormData({ file: payload.image });

    const response = await axios.post(
      `${BASE_URL}/packages-camping/upload/${id}`,
      formData,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling upload image to package api:", error);
  }
};

export const getCampingPackage = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/packages-camping/${id}`);
    return response;
  } catch (error) {
    console.error("Error while calling get package api:", error);
  }
};

export const updateCampingPackage = async (id, payload) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/packages-camping/${id}`,
      payload,
      { headers }
    );
    return response;
  } catch (error) {
    console.error("Error while calling update package api:", error);
  }
};

export const deleteCampingPackage = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/packages-camping/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error while calling delete package api:", error);
  }
};
