import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { CampingPackagesForm } from '../../components/campingPackages/Form';
import { getCampingPackage, updateCampingPackage } from '../../services/api/campingPackages';

export const EditCampingPackages = () => {
  const [packages, setPackages] = useState([]);
  const { id } = useParams();

  const fetchAndSetPackages = async () => {
    const res = await getCampingPackage(id);
    if (res.status === 200) {
      const data = res?.data?.data;
      setPackages(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetPackages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CampingPackagesForm defaultValues={packages} onSave={(payload) => updateCampingPackage(id, payload)} pageID={id} />
  )
}