import axios from "axios";
import { getToken, getTokenForMultiPart } from "./getToken";
import { normalizePayloadToFormData } from "../../utils/form";

const BASE_URL = process.env.REACT_APP_PUBLIC_API;
const headers = getToken();
const headersForMultiPart = getTokenForMultiPart();

export const getCampsites = async ({ page = 1, dateFilter = null }) => {
  try {
    const response = await axios.get(`${BASE_URL}/admin/campsite/list`, {
      headers,
      params: {
        page,
        dateFilter,
      },
    });
    return response;
  } catch (error) {
    console.error("Error while calling get campsites api:", error);
  }
};

export const createCampsite = async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/campsite/create`,
      payload,
      {
        headers,
      }
    );

    if (response.data.data.id) {
      const id = response.data.data.id;
      const thumbnailFormData = normalizePayloadToFormData({
        thumbnail: payload.thumbnail,
      });
      const galleryFormData = normalizePayloadToFormData({
        images: payload.gallery,
      });

      if (payload.thumbnail)
        await uploadCampsiteThumbnail(thumbnailFormData, id);
      if (payload.gallery) await uploadCampsiteGallery(galleryFormData, id);
    }
  } catch (error) {
    console.error("Error while calling create campsite api:", error);
  }
};

export const uploadCampsiteThumbnail = async (payload, id) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/owner/campsite/upload-image/${id}?uploadType=thumbnail`,
      payload,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling upload campsite thumbnail api:", error);
  }
};

export const uploadCampsiteGallery = async (payload, id) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/owner/campsite/upload-image/${id}?uploadType=images`,
      payload,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling upload campsite thumbnail api:", error);
  }
};

export const deleteCampsiteImage = async (id, payload) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/owner/campsite/remove-image/${id}`,
      payload,
      { headers }
    );
    return response;
  } catch (error) {
    console.error("Error while calling get delete Campsite image api:", error);
  }
};

export const updateCampsite = async (id, payload) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/admin/campsite/update/${id}`,
      payload,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    console.error("Error while calling update campsite api:", error);
  }
};

export const getCampsite = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/admin/campsite/${id}`);
    return response;
  } catch (error) {
    console.error("Error while calling get campsite api:", error);
  }
};

export const deleteCampsite = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/campsite/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error while calling delete campsite api:", error);
  }
};
