import React, { useState, useEffect } from "react";
import { updateCampsite } from "../../services/api/campsite";

export const SingleView = ({ campsite }) => {
  const [currentStatus, setCurrentStatus] = useState(campsite.campsiteStatus);

  useEffect(() => {
    if (campsite?.campsiteStatus) {
      setCurrentStatus(campsite.campsiteStatus);
    }
  }, [campsite]);

  const handleStatusChange = async (newStatus) => {
    const payload = { campsiteStatus: newStatus };
    await updateCampsite(campsite.id, payload);
    setCurrentStatus(newStatus);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "SENT_FOR_REVIEW":
        return "bg-blue-500";
      case "UNDER_REVIEW":
        return "bg-yellow-500";
      case "REJECTED":
        return "bg-red-500";
      case "ACCEPTED":
        return "bg-green-500";
      default:
        return "bg-gray-500";
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-6">
        <div className="container p-4 mx-auto bg-gray-100 rounded-md sm:p-6 relative">
          {/* Status Chip */}
          <div
            className={`absolute top-4 right-4 text-white px-4 py-1 rounded-md ${getStatusColor(
              currentStatus
            )}`}
          >
            {currentStatus}
          </div>

          <div className="mb-4 md:mb-0 w-full mx-auto relative">
            <div className="px-0">
              <h1 className="text-4xl font-semibold leading-tight text-gray-800">
                {campsite.title}
              </h1>
              <p className="inline-flex items-center justify-center py-2 mb-2 text-green-700">
                07 February, 2024
              </p>
              <div>
                <p className="text-lg font-semibold text-gray-700">
                  Description: {campsite.description}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700">
                  Price: {campsite.price}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700">
                  Available Seats: {campsite.availableSeats}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700">
                  Category: {campsite.category}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700">
                  campsite Name: {campsite.campsiteName}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700">
                  Province: {campsite.province}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700">
                  City: {campsite.city}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700 pb-6">
                  Address: {campsite.address}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700 pb-6">
                  Site Ownership: {campsite.siteOwnership}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700 pb-6">
                  Number Of Person: {campsite.numberOfPerson}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700 pb-6">
                  Activities: {campsite.activities}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700 pb-6">
                  Available Amenities: {campsite.availableAmenities}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-700 pb-6">
                  Natural Features: {campsite.naturalFeatures}
                </p>
              </div>
              <div>
                <img
                  src={campsite.image?.link}
                  alt="blog-thumbnail"
                  className="w-full mb-6 object-cover lg:rounded h-[500px]"
                />
              </div>
              <div className="flex flex-col gap-6 mb-12">
                <h2 className="text-3xl font-bold">campsite Gallery</h2>
                <div className="flex gap-6">
                  {campsite.gallery &&
                    campsite.gallery.map(({ link }) => (
                      <img
                        key={link}
                        src={link}
                        alt="campsite-gallery"
                        className="rounded-lg shadow-md h-96 w-96 hover:opacity-75"
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Sticky Bar */}
      <div className="fixed bottom-0 left-0 w-full bg-gray-100 p-4 shadow-md flex justify-center">
        {currentStatus === "SENT_FOR_REVIEW" && (
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
            onClick={() => handleStatusChange("UNDER_REVIEW")}
          >
            START_UNDER_REVIEW
          </button>
        )}
        {currentStatus === "UNDER_REVIEW" && (
          <div className="flex gap-4">
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-md"
              onClick={() => handleStatusChange("REJECTED")}
            >
              Reject
            </button>
            <button
              className="px-4 py-2 bg-green-500 text-white rounded-md"
              onClick={() => handleStatusChange("ACCEPTED")}
            >
              Accept
            </button>
          </div>
        )}
        {currentStatus === "REJECTED" && (
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded-md"
            disabled
          >
            REJECTED
          </button>
        )}
        {currentStatus === "ACCEPTED" && (
          <button
            className="px-4 py-2 bg-teal-500 text-white rounded-md"
            disabled
          >
            ACCEPTED
          </button>
        )}
      </div>
    </div>
  );
};
