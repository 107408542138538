import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "universal-cookie";

const BASE_URL = process.env.REACT_APP_PUBLIC_API;

export const loginfetch = createAsyncThunk("login", async (credentials) => {
  try {
    const response = await axios.post(
      BASE_URL.concat("/auth/admin/login"),
      credentials
    );
    return response.data;
  } catch (err) {
    if (!err?.response) {
      console.log("NO Server Response");
    } else if (err?.response?.status === 400) {
      console.log("Missing Password or UserName");
    } else if (err?.response?.status === 401) {
      console.log("UnAuthorized");
    }
    console.log(err);
  }
});

export const logoutfetch = createAsyncThunk("logout", () => {
  try {
    const cookies = new Cookies();
    return cookies.remove("token");
  } catch (err) {
    if (!err?.response) {
      console.log("NO Server Response");
    } else if (err?.response?.status === 400) {
      console.log("Missing Password or UserName");
    } else if (err?.response?.status === 401) {
      console.log("UnAuthorized");
    }
    console.log(err);
  }
});

export const getMe = createAsyncThunk("getMe", async (jwt_token) => {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${jwt_token}`,
  };
  const cookies = new Cookies();
  if (cookies.get("token")) {
    try {
      const response = await axios.get(
        BASE_URL.concat("/auth/admin/me"),
        { headers },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
});
