import React from "react";

export const SingleView = ({ pointOfInterest }) => {
  const roadTypeColors = {
    gravel: "gray",
    mud: "brown",
    grass: "green",
    // Add more road types and colors as needed
  };

  const renderRoadTypes = () => {
    return pointOfInterest.roadTypes.map((roadType, index) => {
      const color = roadTypeColors[roadType.roadType] || "black"; // Default color black if not found
      const widthPercentage = `${parseInt(roadType.totalPercentage)}%`;

      return (
        <div
          className="border border-red-400"
          key={index}
          style={{
            backgroundColor: color,
            width: widthPercentage,
            height: "100px",
            display: "inline-block",
            position: "relative",
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <span className="font-semibold" style={{ color: "white" }}>
              {roadType.roadType}
            </span>
            <br />
            <span className="font-semibold" style={{ color: "white" }}>
              {roadType.roadDistance}km
            </span>
            <br />
            <span className="font-semibold" style={{ color: "white" }}>
              Road Percentage: {roadType.totalPercentage}
            </span>
          </span>
        </div>
      );
    });
  };

  return (
    <div>
      <div className="flex flex-col gap-6">
        <div className="container p-4 mx-auto bg-gray-100 rounded-md sm:p-6">
          <div className="mb-4 md:mb-0 w-full mx-auto relative">
            <div className="px-0">
              <h1 className="text-4xl font-semibold leading-tight text-gray-800">
                {pointOfInterest.title}
              </h1>
              {/* Display poiType chip */}
              <span className="inline-block px-3 py-1 text-sm font-semibold text-white bg-indigo-500 rounded-full">
                {pointOfInterest.poiType}
              </span>
              <p className="inline-flex items-center justify-center px-5 py-2 mb-2 text-green-700">
                07 February, 2024
              </p>

              {/* Conditionally display fields based on poiType */}
              {pointOfInterest.poiType === "TRAIL" && (
                <div className="flex gap-6 mb-6">
                  {/* Render length, trailGrade, and roadTypes fields here */}
                  <div className="flex items-center p-4 bg-white rounded-lg shadow-xs">
                    <div className="p-3 mr-4 text-red-500 bg-red-100 rounded-full">
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                      </svg>
                    </div>
                    <div>
                      <p className="mb-2 text-sm font-medium text-gray-600">
                        Length
                      </p>
                      <p className="text-lg font-semibold text-gray-700">
                        {pointOfInterest.length}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-4 bg-white rounded-lg shadow-xs">
                    <div className="p-3 mr-4 text-red-500 bg-red-100 rounded-full">
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                      </svg>
                    </div>
                    <div>
                      <p className="mb-2 text-sm font-medium text-gray-600">
                        Trail Grade
                      </p>
                      <p className="text-lg font-semibold text-gray-700">
                        {pointOfInterest.trailGrade}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {pointOfInterest.poiType === "TRAIL" && (
                <div className="fle flex-col mb-6">
                  <div className="mb-2 text-lg font-semibold text-gray-600">
                    Road Types
                  </div>
                  {renderRoadTypes()}
                </div>
              )}
              <div className="flex items-center p-4 bg-white rounded-lg shadow-xs mb-6">
                <div className="p-3 mr-4 text-red-500 bg-red-100 rounded-full">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                  </svg>
                </div>
                <div>
                  <p className="mb-2 text-sm font-medium text-gray-600">
                    Altitude
                  </p>
                  <p className="text-lg font-semibold text-gray-700">
                    {pointOfInterest.altitude}
                  </p>
                </div>
              </div>

              <div>
                <img
                  src={pointOfInterest.image?.link}
                  alt="blog-thumbnail"
                  className="w-full object-cover lg:rounded h-[500px] mb-6"
                />
              </div>

              <div className="w-full px-4 mb-12 text-lg leading-relaxed text-gray-700 lg:px-0">
                <div
                  dangerouslySetInnerHTML={{
                    __html: pointOfInterest.description,
                  }}
                />
              </div>

              <div className="flex flex-col gap-6 mb-6">
                <h2 className="text-3xl font-bold">
                  Point Of Interest Gallery
                </h2>
                <div className="flex gap-6">
                  {pointOfInterest.gallery &&
                    pointOfInterest.gallery.map(({ link }) => {
                      return (
                        <img
                          key={link}
                          src={link}
                          alt="blog-gallery"
                          className="rounded-lg shadow-md h-96 w-96 hover:opacity-75"
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
