import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import {
  activitiesList,
  amenitiesList,
  emptyDefaultValues,
  naturalFeaturesList,
  setFormValues,
} from "./utils";
import {
  deleteCampsiteImage,
  uploadCampsiteGallery,
  uploadCampsiteThumbnail,
} from "../../services/api/campsite";
import Spinner from "../ui/Spinner";
import { ImageUploadField } from "../ui/ImageUploadField";

export const CampsiteForm = ({
  defaultValues = emptyDefaultValues,
  onSave = () => {},
  updateCampsite = false,
}) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    defaultValues,
  });

  const availableAmenities = watch("availableAmenities");
  const activities = watch("activities");
  const naturalFeatures = watch("naturalFeatures");

  const onSubmit = async (payload) => {
    try {
      const data = payload;
      reset();
      navigate("/campsite");
      return onSave(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      setValue(key, defaultValues[key]);
    });
    setFormValues(defaultValues, setValue);
  }, [defaultValues, setValue]);

  return (
    <form
      className="px-8 space-y-8 divide-y divide-gray-200"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Campsite
            </h3>
            <p className="max-w-2xl mt-1 text-sm text-gray-500">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>

          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="title"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Title
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    autoComplete="title"
                    {...register("title")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.title && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.title.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Description
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <textarea
                  id="description"
                  name="description"
                  rows={3}
                  className="block w-full max-w-lg rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                  defaultValue={""}
                  {...register("description")}
                />
                {errors?.description && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.description.message}
                  </p>
                )}
                <p className="mt-2 text-sm text-gray-500">
                  Write a few sentences about your campsite.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="price"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Price
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="number"
                    name="price"
                    id="price"
                    autoComplete="price"
                    {...register("price")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.price && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.price.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="price"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Available Seats
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="number"
                    name="availableSeats"
                    id="availableSeats"
                    autoComplete="availableSeats"
                    {...register("availableSeats", { valueAsNumber: true })}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.availableSeats && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.availableSeats.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Category
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <select
                    id="category"
                    name="category"
                    autoComplete="category"
                    {...register("category")}
                    className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="">Select Category</option>
                    <option value="Bagpacker">Bagpacker</option>
                    <option value="Camper">Camper</option>
                    <option value="Campstay">Campstay</option>
                  </select>
                </div>
                {errors?.category && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.category.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="siteOwnership"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Site Ownership
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <select
                    id="siteOwnership"
                    name="siteOwnership"
                    autoComplete="siteOwnership"
                    {...register("siteOwnership")}
                    className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="">Select Ownership</option>
                    <option value="Public">Public</option>
                    <option value="Private">Private</option>
                  </select>
                </div>
                {errors?.siteOwnership && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.siteOwnership.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Activities
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <Select
                    className="w-full"
                    isMulti
                    value={
                      !!activities &&
                      activities.map((i) => {
                        return {
                          label: i,
                          value: i,
                        };
                      })
                    }
                    options={activitiesList.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(values) => {
                      setValue(
                        "activities",
                        values.map((i) => i.value)
                      );
                    }}
                    placeholder="Select activities"
                  />
                </div>

                {errors?.activities && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.activities.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Available Amenities
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <Select
                    className="w-full"
                    isMulti
                    value={
                      !!availableAmenities &&
                      availableAmenities.map((i) => {
                        return {
                          label: i,
                          value: i,
                        };
                      })
                    }
                    options={amenitiesList.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(values) => {
                      setValue(
                        "availableAmenities",
                        values.map((i) => i.value)
                      );
                    }}
                    placeholder="Select amenities"
                  />
                </div>
                {errors?.availableAmenities && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.availableAmenities.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Natural Features
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <Select
                    className="w-full"
                    isMulti
                    value={
                      !!naturalFeatures &&
                      naturalFeatures.map((i) => {
                        return {
                          label: i,
                          value: i,
                        };
                      })
                    }
                    options={naturalFeaturesList.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(values) => {
                      setValue(
                        "naturalFeatures",
                        values.map((i) => i.value)
                      );
                    }}
                    placeholder="Select natural features"
                  />
                </div>
                {errors?.naturalFeatures && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.naturalFeatures.message}
                  </p>
                )}
              </div>
            </div>

            <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Thumbnail Image
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="max-w-xl">
                  {updateCampsite ? (
                    <ImageUploadField
                      control={control}
                      multipleUploadAllowed={false}
                      name="thumbnail"
                      updateAction={updateCampsite}
                      id={defaultValues.id}
                      onUpload={uploadCampsiteThumbnail}
                      onDelete={deleteCampsiteImage}
                    />
                  ) : (
                    <ImageUploadField
                      control={control}
                      multipleUploadAllowed={false}
                      name="thumbnail"
                      updateAction={updateCampsite}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Gallery Images
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="max-w-xl">
                  {updateCampsite ? (
                    <ImageUploadField
                      control={control}
                      multipleUploadAllowed={true}
                      name="gallery"
                      updateAction={updateCampsite}
                      id={defaultValues.id}
                      onUpload={uploadCampsiteGallery}
                      onDelete={deleteCampsiteImage}
                    />
                  ) : (
                    <ImageUploadField
                      control={control}
                      multipleUploadAllowed={true}
                      name="gallery"
                      updateAction={updateCampsite}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8 space-y-6 sm:space-y-5 sm:pt-10">
          <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Location Details
            </h3>
            <p className="max-w-2xl mt-1 text-sm text-gray-500">
              Use a permanent address where you are located.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="campsiteName"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Campsite Name
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="campsiteName"
                  id="campsiteName"
                  autoComplete="off"
                  {...register("location.campsiteName")}
                  className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors?.campsiteName && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.campsiteName.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="province"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                State / Province
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <select
                  id="province"
                  name="province"
                  autoComplete="off"
                  {...register("location.province")}
                  className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value="">Select Province</option>
                  <option value="Bagmati">Bagmati</option>
                  <option value="Gandaki">Gandaki</option>
                  <option value="Karnali">Karnali</option>
                  <option value="Madhesh">Madhesh</option>
                  <option value="Lumbini">Lumbini</option>
                  <option value="Koshi">Koshi</option>
                  <option value="Sudurpashchim">Sudurpashchim</option>
                </select>
                {errors?.province && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.province.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="city"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                City
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="city"
                  id="city"
                  autoComplete="off"
                  {...register("location.city")}
                  className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                />
                {errors?.city && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.city.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="area"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Area
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="area"
                  id="area"
                  autoComplete="off"
                  {...register("location.area")}
                  className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors?.area && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.area.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="address"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Address
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="address"
                  id="address"
                  autoComplete="off"
                  {...register("location.address")}
                  className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors?.address && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.address.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end gap-x-3">
          <button
            type="button"
            onClick={() => navigate("/campsite")}
            className="px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="inline-flex justify-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {isSubmitting ? <Spinner /> : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
};
