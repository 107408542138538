import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { CampsiteForm } from "../../components/campsite/Form";
import { normalizeEditCampsite } from "../../components/campsite/utils";
import { updateCampsite, getCampsite } from "../../services/api/campsite";

export const EditCampsite = () => {
  const [campsite, setCampsite] = useState([]);
  const { id } = useParams();

  const fetchAndSetCampsite = async () => {
    const res = await getCampsite(id);
    if (res.status === 200) {
      const data = res?.data?.data;
      setCampsite(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetCampsite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CampsiteForm
      defaultValues={normalizeEditCampsite(campsite)}
      onSave={(payload) => updateCampsite(id, payload)}
      updateCampsite={true}
    />
  );
};
