import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { TourPackagesForm } from '../../components/tourPackages/Form';
import { getTourPackage, updateTourPackage } from '../../services/api/tourPackages';

export const EditTourPackages = () => {
  const [packages, setPackages] = useState([]);
  const { id } = useParams();

  const fetchAndSetPackages = async () => {
    const res = await getTourPackage(id);
    if (res.status === 200) {
      const data = res?.data?.data;
      setPackages(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetPackages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TourPackagesForm defaultValues={packages} onSave={(payload) => updateTourPackage(id, payload)} pageID={id} />
  )
}