import axios from "axios";
import { getToken, getTokenForMultiPart } from "./getToken";
import { normalizePayloadToFormData } from "../../utils/form";

const BASE_URL = process.env.REACT_APP_PUBLIC_API;
const headers = getToken();
const headersForMultiPart = getTokenForMultiPart();

export const getOrganizations = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/organization`);
    return response;
  } catch (error) {
    console.error("Error while calling get organizations api:", error);
  }
};

export const createOrganization = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/organization`, payload, {
      headers,
    });
    const organizationId = response.data.data._id;

    const uploadResponse = await uploadImageToOrganization(
      organizationId,
      payload
    );
    return uploadResponse;
  } catch (error) {
    console.error("Error while calling create organization api:", error);
  }
};

export const uploadImageToOrganization = async (organizationId, payload) => {
  try {
    const formData = normalizePayloadToFormData({ file: payload.image });

    const response = await axios.post(
      `${BASE_URL}/organization/upload/${organizationId}`,
      formData,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error(
      "Error while calling upload image to organization api:",
      error
    );
  }
};

export const getOrganization = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/organization/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error while calling get organization api:", error);
  }
};

export const updateOrganization = async (id, payload) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/organization/${id}`,
      payload,
      { headers }
    );
    return response;
  } catch (error) {
    console.error("Error while calling update organization api:", error);
  }
};

export const deleteOrganization = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/organization/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error while calling delete organization api:", error);
  }
};
