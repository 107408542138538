import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { OrganizationForm } from '../../components/organization/Form'
import { normalizeOrganization } from '../../components/organization/utils'
import { updateOrganization, getOrganization } from '../../services/api/organization'

export const EditOrganization = () => {
  const [organization, setOrganization] = useState([]);
  const { id } = useParams();

  const fetchAndSetOrganization = async () => {
    const res = await getOrganization(id);
    if (res.status === 200) {
      const data = res?.data?.data;
      setOrganization(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetOrganization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <OrganizationForm defaultValues={normalizeOrganization(organization)} onSave={(payload) => updateOrganization(id, payload)} />
  )
}
