import React from "react";
import { Routes, Route } from "react-router-dom";

import { createOrganization } from "./services/api/organization.js";
import { createCampsite } from "./services/api/campsite.js";
import { createTourPackage } from "./services/api/tourPackages.js";
import { createCampingPackage } from "./services/api/campingPackages.js";
import { createBlog, createPointOfInterest } from "./services/api/blogs.js";
import { createGear } from "./services/api/gear.js";

import { CampsiteForm } from "./components/campsite/Form";
import { OrganizationForm } from "./components/organization/Form";
import { TourPackagesForm } from "./components/tourPackages/Form";
import { CampingPackagesForm } from "./components/campingPackages/Form";
import { GearForm } from "./components/gear/Form";

import { Organization } from "./pages/organization/Organization";
import { EditOrganization } from "./pages/organization/EditOrganization";

import { Campsite } from "./pages/campsite/Campsite";
import { SingleCampsite } from "./pages/campsite/SingleCampsite.js";
import { EditCampsite } from "./pages/campsite/EditCampsite";

import { Gear } from "./pages/gears/Gear.js";
import { EditGear } from "./pages/gears/EditGear.js";

import { TourPackages } from "./pages/tourPackages/TourPackages";
import { EditTourPackages } from "./pages/tourPackages/EditTourPackages";

import { CampingPackages } from "./pages/campingPackages/CampingPackages.js";
import { EditCampingPackages } from "./pages/campingPackages/EditCampingPackages";

import { Blogs } from "./pages/blogs/Blogs.js";
import { BlogForm } from "./components/blogs/Form.js";
import { SingleBlog } from "./pages/blogs/SingleBlog.js";
import { EditBlog } from "./pages/blogs/EditBlog.js";

import { PointOfInterest } from "./pages/pointOfInterest/PointOfInterest.js";
import { SinglePointOfInterest } from "./pages/pointOfInterest/SinglePointOfInterest.js";
import { PointOfInterestForm } from "./components/pointOfInterest/Form.js";

import { ReviewRequest } from "./pages/reviewRequests/ReviewRequest.js";

import { Home } from "./pages/Home.js";
import { Login } from "./pages/Login.js";

import { Layout } from "./components/Layout";
import "./App.css";
import { EditPointOfInterest } from "./pages/pointOfInterest/EditPointOfInterest.js";

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<Layout />}>
          <Route path="/" index element={<Home />} />

          <Route path="/campsite" element={<Campsite />} />
          <Route path="/campsite/:id/view" element={<SingleCampsite />} />
          <Route
            path="/campsite/form"
            element={
              <CampsiteForm onSave={(payload) => createCampsite(payload)} />
            }
          />
          <Route path="/campsite/:id" element={<EditCampsite />} />

          <Route path="/review-requests" element={<ReviewRequest />} />

          <Route path="/gears" element={<Gear />} />
          <Route
            path="/gear/form"
            element={<GearForm onSave={(payload) => createGear(payload)} />}
          />
          <Route path="/gear/:id" element={<EditGear />} />

          <Route path="/organization" element={<Organization />} />
          <Route
            path="/organization/form"
            element={
              <OrganizationForm
                onSave={(payload) => createOrganization(payload)}
              />
            }
          />
          <Route path="/organization/:id" element={<EditOrganization />} />

          <Route path="/blog" element={<Blogs />} />
          <Route path="/blog/:id/view" element={<SingleBlog />} />
          <Route
            path="/blog/form"
            element={<BlogForm onSave={(payload) => createBlog(payload)} />}
          />
          <Route path="/blog/:id/edit" element={<EditBlog />} />

          <Route path="/point-of-interest" element={<PointOfInterest />} />
          <Route
            path="/point-of-interest/:id/view"
            element={<SinglePointOfInterest />}
          />
          <Route
            path="/point-of-interest/:id/edit"
            element={<EditPointOfInterest />}
          />
          <Route
            path="/point-of-interest/form"
            element={
              <PointOfInterestForm
                onSave={(payload) => createPointOfInterest(payload)}
              />
            }
          />

          <Route path="/tour-packages" element={<TourPackages />} />
          <Route
            path="/tour-packages/form"
            element={
              <TourPackagesForm
                onSave={(id, payload) => createTourPackage(id, payload)}
              />
            }
          />
          <Route path="/tour-packages/:id" element={<EditTourPackages />} />

          <Route path="/camping-packages" element={<CampingPackages />} />
          <Route
            path="/camping-packages/form"
            element={
              <CampingPackagesForm
                onSave={(id, payload) => createCampingPackage(id, payload)}
              />
            }
          />
          <Route
            path="/camping-packages/:id"
            element={<EditCampingPackages />}
          />
        </Route>
      </Routes>
    </>
  );
}
