import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import { getGears, deleteGear } from "../../services/api/gear";

export function List() {
  const [gears, setGears] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const navigate = useNavigate();

  const fetchAndSetGears = async (page, dateFilter) => {
    const res = await getGears({ page, dateFilter });
    if (res.status === 200) {
      const data = res?.data;
      setGears(data);
      setTotalResults(data.response.payload.pagination.total);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetGears(currentPage, dateFilter);
  }, [currentPage, dateFilter]);

  const handleDelete = async (id) => {
    await deleteGear(id);
    return fetchAndSetGears();
  };

  const handleDateFilterChange = (event) => {
    // Handle date filter change
    setDateFilter(event.target.value);
    setCurrentPage(1); // Reset page when filter changes
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalResults / 10)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Gears
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the gears with full details.
          </p>
        </div>

        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => navigate("/gear/form")}
            className="block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add Gear
          </button>
        </div>
      </div>

      <div className="mt-4">
        <label
          htmlFor="dateFilter"
          className="block text-sm font-medium text-gray-700"
        >
          Filter by Date:
        </label>
        <select
          id="dateFilter"
          name="dateFilter"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={dateFilter}
          onChange={handleDateFilterChange}
        >
          <option value="">All Dates</option>
          <option value="createdAtAsc">Oldest First (Created)</option>
          <option value="createdAtDesc">Newest First (Created)</option>
          <option value="updatedAtAsc">Oldest First (Updated)</option>
          <option value="updatedAtDesc">Newest First (Updated)</option>
        </select>
      </div>

      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Real Price
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Discounted Price
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {!!Object.keys(gears).length &&
                  gears.response.data.map((gear) => {
                    return (
                      <tr key={gear.id}>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                          {gear.name}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {gear.description}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {gear.quantity}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {gear.quantity}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {gear.realPrice}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {gear.discountedPrice}
                        </td>

                        <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                          <button
                            type="button"
                            title="Edit"
                            onClick={() => navigate(`/gear/${gear.id}`)}
                            className="p-1 mr-4 text-indigo-600 rounded-full hover:bg-gray-200"
                          >
                            <PencilSquareIcon className="w-5 h-5" />
                            <span className="sr-only">, {gear.name}</span>
                          </button>

                          <button
                            type="button"
                            title="Delete"
                            onClick={() => handleDelete(`${gear._id}`)}
                            className="p-1 text-red-600 rounded-full hover:bg-gray-200"
                          >
                            <TrashIcon className="w-5 h-5" />
                            <span className="sr-only">, {gear.name}</span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* Pagination*/}
            <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
              <div className="flex flex-1 justify-between sm:hidden">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Previous
                </button>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === Math.ceil(totalResults / 10)}
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Next
                </button>
              </div>
              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">
                      {Math.min((currentPage - 1) * 10 + 1, totalResults)}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {Math.min(currentPage * 10, totalResults)}
                    </span>{" "}
                    of <span className="font-medium">{totalResults}</span>{" "}
                    results
                  </p>
                </div>
                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    {[...Array(Math.ceil(totalResults / 10)).keys()].map(
                      (page) => (
                        <button
                          key={page + 1}
                          onClick={() => setCurrentPage(page + 1)}
                          className={`relative inline-flex items-center ${
                            page + 1 === currentPage
                              ? "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          } px-4 py-2 text-sm font-semibold focus:z-20 focus:outline-offset-0`}
                        >
                          {page + 1}
                        </button>
                      )
                    )}
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === Math.ceil(totalResults / 10)}
                      className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
