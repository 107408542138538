import * as yup from "yup";

export const emptyDefaultValues = {
  image: [],
  name: "",
  description: "",
  phone: "",
  email: "",
  address: "",
};

export const validationSchema = yup.object({
  image: yup
    .array()
    .min(1, "Image is required.")
    .max(1, "Please limit the Image number to one "),
  name: yup
    .string()
    .required('Name is required.')
    .min(3, 'Must be more than 3 characters.'),
  description: yup
    .string()
    .required("Description is required.")
    .min(10, 'Must be more than 10 characters.'),
  phone: yup
    .string()
    .matches(/^[0-9]{10}$/, 'Invalid phone number.')
    .required('Phone number is required'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is required.'),
  address: yup.string().required("Address is required."),
});

export const normalizeOrganization = (organization) => {
  const data = { ...organization };

  Object.keys(data).forEach((key) => {
    if (typeof emptyDefaultValues?.[key] === "undefined") {
      delete data[key];
    }
  });

  return data;
};

export const setFormValues = (defaultValues, setValue) => {
  const { image } = defaultValues;
  return setValue("image", Array.isArray(image) ? [...image] : [image]);
};
