import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { SingleView } from "../../components/campsite/SingleView";
import { updateCampsite, getCampsite } from "../../services/api/campsite";

export function SingleCampsite() {
  const [campsite, setCampsite] = useState([]);
  const { id } = useParams();


  const fetchAndSetCampsite = async () => {
    const res = await getCampsite(id);
    
    if (res.status === 200) {
      const data = res?.data?.data;
      setCampsite(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetCampsite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SingleView campsite={campsite} />;
}
