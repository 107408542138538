import React, { useState, useEffect } from 'react'
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TrashIcon,
  PlusIcon
} from "@heroicons/react/24/outline";

import { getOrganizations } from '../../services/api/organization'
import Spinner from '../ui/Spinner';
import { emptyDefaultValues, normalizeTourPackagePayload, normalizeTourPackages, setFormValues, validationSchema } from './utils'
import { ImageUploadField } from '../ui/ImageUploadField';

export const TourPackagesForm = ({ defaultValues = emptyDefaultValues, onSave = () => { }, pageID }) => {
  const [organizations, setOrganizations] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    fields: image,
    append: appendImages,
    remove: removeImages,
  } = useFieldArray({
    control,
    name: "image",
  });

  const {
    fields: itinerary,
    append: appendItinerary,
    remove: removeItinerary,
  } = useFieldArray({
    name: "itinerary",
    control,
  });

  const fetchAndSetOrganizations = async () => {
    const res = await getOrganizations();
    if (res.status === 200) {
      const data = res?.data?.data;
      setOrganizations(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetOrganizations()
  }, [])

  const onSubmit = async (payload) => {
    try {
      const data = normalizeTourPackagePayload(payload)
      reset()
      navigate('/tour-packages')

      if (payload.organizationId) {
        const id = payload.organizationId;
        return onSave(id, normalizeTourPackages(data));
      }

      return onSave(normalizeTourPackages(data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    Object.keys(normalizeTourPackages(defaultValues)).forEach((key) => {
      setValue(key, defaultValues[key])
    });
    setFormValues(defaultValues, setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [defaultValues, setValue]);

  return (
    <form className="px-8 space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">Package</h3>
            <p className="max-w-2xl mt-1 text-sm text-gray-500">
              This information will be displayed publicly so be careful what you share.
            </p>
          </div>

          <div className="space-y-6 sm:space-y-5">
            {!!pageID ? (
              ''
            ) : (
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="organization" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Organization
                </label>

                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <select
                      id="organizationId"
                      name="organizationId"
                      autoComplete="off"
                      {...register("organizationId")}
                      className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option></option>
                      {organizations.map((organization) => {
                        return (
                          <option key={organization._id} value={organization._id}>{organization.name}</option>
                        )
                      }
                      )}
                    </select>
                  </div>
                  {errors?.organizationId && (
                    <p className="my-3 text-sm text-rose-500">
                      {errors.organizationId.message}
                    </p>
                  )}
                </div>
              </div>
            )}

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Title
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    autoComplete="off"
                    {...register("title")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.title && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.title.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Description
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <textarea
                  id="description"
                  name="description"
                  rows={3}
                  {...register("description")}
                  className="block w-full max-w-lg rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                />
                <p className="mt-2 text-sm text-gray-500">Write a few sentences about your package.</p>
                {errors?.description && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.description.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Price
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="number"
                    name="price"
                    id="price"
                    autoComplete="off"
                    {...register("price")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.price && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.price.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="duration" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Duration
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="number"
                    name="duration"
                    id="duration"
                    autoComplete="off"
                    {...register("duration")}
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors?.duration && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.duration.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="tripGrade" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Trip Grade
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <select
                    id="tripGrade"
                    name="tripGrade"
                    autoComplete="off"
                    {...register('tripGrade')}
                    className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option></option>
                    <option>Easy</option>
                    <option>Medium</option>
                    <option>Hard</option>
                  </select>
                </div>
                {errors?.tripGrade && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.tripGrade.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="destinations" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Destinations
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="destinations"
                    id="destinations"
                    autoComplete="destinations"
                    {...register("destinations")}
                    placeholder="Destination1, Destination2, ..."
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">Please write the destinations one by one, separating them with comma ",". </p>
                {errors?.destinations && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.destinations.message}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="amenitiesProvided" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Amenities Provided
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <input
                    type="text"
                    name="amenitiesProvided"
                    id="amenitiesProvided"
                    autoComplete="amenitiesProvided"
                    {...register("amenitiesProvided")}
                    placeholder="Amenity1, Amenity2, ..."
                    className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">Please write the amenities one by one, separating them with comma ",". </p>
                {errors?.amenitiesProvided && (
                  <p className="my-3 text-sm text-rose-500">
                    {errors.amenitiesProvided.message}
                  </p>
                )}
              </div>
            </div>

            <ImageUploadField image={image} appendImages={appendImages} removeImages={removeImages} errors={errors} />

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="itinerary" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Itinerary
              </label>

              <div className="mt-2 sm:col-span-2 sm:mt-0">
                {itinerary.map((field, index) => {
                  return (
                    <div
                      className="flex flex-col w-full gap-4 mb-4"
                      key={field.id}
                    >
                      <div className="flex items-center justify-between max-w-lg">
                        <span className='font-bold text-gray-400 text-md'>
                          #{index + 1}
                        </span>

                        <button
                          type="button"
                          className="p-2 rounded-full hover:bg-gray-200 "
                          onClick={() => removeItinerary(index)}
                        >
                          <TrashIcon height={20} />
                        </button>
                      </div>

                      <div className='flex max-w-lg gap-4'>
                        <input
                          type="number"
                          name="itinerary"
                          id="itinerary"
                          placeholder='Day'
                          {...register(`itinerary.${index}.day`)}
                          className="block w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />

                        <input
                          type="text"
                          name="itinerary"
                          id="itinerary"
                          placeholder='Name'
                          {...register(`itinerary.${index}.name`)}
                          className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>

                      <textarea
                        id="description"
                        placeholder="Description"
                        rows={4}
                        {...register(`itinerary.${index}.description`)}
                        className="block w-full max-w-lg p-3 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
                      />
                    </div>
                  );
                })}

                <button
                  type="button"
                  className="flex items-center gap-2 px-3 py-2 bg-gray-100 rounded-full hover:bg-gray-200"
                  onClick={() =>
                    appendItinerary({
                      day: "",
                      name: "",
                      description: "",
                    })
                  }
                >
                  <PlusIcon height={20} />
                  <span className='text-sm'>
                    Add Itinerary
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end gap-x-3">
          <button
            type="button"
            onClick={() => navigate('/tour-packages')}
            className="px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={isSubmitting}
            className="inline-flex justify-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {isSubmitting ? <Spinner /> : "Save"}
          </button>
        </div>
      </div>
    </form>
  )
}
