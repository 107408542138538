import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { emptyDefaultValues, setFormValues } from "./utils";
import { TextEditor } from "../TextEditor";
import Spinner from "../ui/Spinner";
import { ImageUploadField } from "../ui/ImageUploadField";
import {
  deletePointOfInterestImage,
  uploadPointOfInterestGallery,
  uploadPointOfInterestThumbnail,
} from "../../services/api/blogs";

export const PointOfInterestForm = ({
  defaultValues = emptyDefaultValues,
  onSave = () => {},
  updatePointOfInterest = false,
}) => {
  const navigate = useNavigate();
  const [poiType, setPoiType] = useState(defaultValues.poiType);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
  });

  const {
    fields: roadTypeFields,
    append: appendRoadType,
    remove: removeRoadType,
  } = useFieldArray({
    name: "roadTypes",
    control,
  });

  const onSubmit = async (payload) => {
    try {
      reset();
      navigate("/point-of-interest");
      return onSave(payload);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDescriptionChange = (editorState) => {
    setValue("description", editorState);
  };

  const editorContent = watch("description");

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      setValue(key, defaultValues[key]);
    });

    setFormValues(defaultValues, setValue);

    // Set poiType state based on defaultValues
    setPoiType(defaultValues.poiType);
  }, [defaultValues, setValue]);

  // Function to handle section type change
  const handlePoiTypeChange = (e) => {
    setPoiType(e.target.value);
  };

  return (
    <div>
      <form
        className="px-8 space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Point Of Interest
              </h3>
              <p className="max-w-2xl mt-1 text-sm text-gray-500">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>

            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Title
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      id="title"
                      name="title"
                      {...register("title")}
                      className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {errors?.title && (
                    <p className="my-3 text-sm text-rose-500">
                      {errors.title.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Section Type Selection */}
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="poiType"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            POI Type
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <select
              id="poiType"
              name="poiType"
              {...register("poiType")}
              value={poiType}
              onChange={handlePoiTypeChange}
              className="block w-full min-w-0 rounded-md border-gray-300 py-1.5 px-3 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="TRAIL">Trail Section</option>
              <option value="PLACE">Place</option>
            </select>
          </div>
        </div>

        {/* Length Field (Appears when Trail Section is selected) */}
        {poiType !== "PLACE" && (
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="length"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Length
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  id="length"
                  name="length"
                  {...register("length")}
                  className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {errors?.length && (
                <p className="my-3 text-sm text-rose-500">
                  {errors.length.message}
                </p>
              )}
            </div>
          </div>
        )}

        {/* Trails Grade Field (Appears when Trail Section is selected) */}
        {poiType !== "PLACE" && (
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="trailGrade"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Trail Grade
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  id="trailGrade"
                  name="trailGrade"
                  {...register("trailGrade")}
                  className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {errors?.trailGrade && (
                <p className="my-3 text-sm text-rose-500">
                  {errors.trailGrade.message}
                </p>
              )}
            </div>
          </div>
        )}

        {/* Road Types */}
        {poiType !== "PLACE" && (
          <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
            <label
              htmlFor="roadTypes"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Road Types
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="divide-y">
                {roadTypeFields.map((field, index) => (
                  <div
                    className="flex flex-col w-full gap-2 py-3"
                    key={field.id}
                  >
                    <div className="flex items-center justify-between w-full mb-4">
                      <div className="text-lg font-bold text-gray-400">
                        #{index + 1}
                      </div>
                      <button
                        type="button"
                        className="p-2 rounded-full hover:bg-gray-200 w-max"
                        onClick={() => removeRoadType(index)}
                      >
                        Remove
                      </button>
                    </div>
                    <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-4">
                      {/* Input field for road type */}
                      <input
                        type="text"
                        placeholder="Road Type"
                        {...register(`roadTypes.${index}.roadType`)}
                        className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {/* Input field for road distance */}
                      <input
                        type="text"
                        placeholder="Road Distance"
                        {...register(`roadTypes.${index}.roadDistance`)}
                        className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-5 text-xl text-right text-indigo-600 font-small">
                <button
                  type="button"
                  className="flex items-center text-sm font-light border-b border-indigo-200 hover:bg-gray-200"
                  onClick={() => appendRoadType()}
                >
                  Add Road Type
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Description
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              <TextEditor
                onChange={handleDescriptionChange}
                value={editorContent}
              />
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="altitude"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Altitude
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="flex max-w-lg rounded-md shadow-sm">
              <input
                type="text"
                id="altitude"
                name="altitude"
                {...register("altitude")}
                className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errors?.altitude && (
              <p className="my-3 text-sm text-rose-500">
                {errors.altitude.message}
              </p>
            )}
          </div>
        </div>

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Thumbnail Image
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              {updatePointOfInterest ? (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={false}
                  name="thumbnail"
                  updateAction={updatePointOfInterest}
                  id={defaultValues.id}
                  onUpload={uploadPointOfInterestThumbnail}
                  onDelete={deletePointOfInterestImage}
                />
              ) : (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={false}
                  name="thumbnail"
                  updateAction={updatePointOfInterest}
                />
              )}
            </div>
          </div>
        </div>

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Gallery Images
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              {updatePointOfInterest ? (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={true}
                  name="gallery"
                  updateAction={updatePointOfInterest}
                  id={defaultValues.id}
                  onUpload={uploadPointOfInterestGallery}
                  onDelete={deletePointOfInterestImage}
                />
              ) : (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={true}
                  name="gallery"
                  updateAction={updatePointOfInterest}
                />
              )}
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end gap-x-3">
            <button
              type="button"
              onClick={() => navigate("/point-of-interest")}
              className="px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex justify-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {isSubmitting ? <Spinner /> : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
