import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import Plus from "@heroicons/react/24/solid/PlusIcon";

import { emptyDefaultValues, setFormValues } from "./utils";
import { TextEditor } from "../TextEditor";
import Spinner from "../ui/Spinner";
import {
  deleteBlogImage,
  getPointOfInterestsForCampsiteForm,
  uploadBlogGallery,
  uploadBlogThumbnail,
} from "../../services/api/blogs";
import { ImageUploadField } from "../ui/ImageUploadField";

export const BlogForm = ({
  defaultValues = emptyDefaultValues,
  onSave = () => {},
  updateBlog = false,
}) => {
  const [pointOfInterest, setPointOfInterest] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
  });

  const {
    fields: pointOfInterests,
    append: appendPointOfInterests,
    remove: removePointOfInterests,
  } = useFieldArray({
    name: "pointOfInterests",
    control,
  });

  const onSubmit = async (payload) => {
    try {
      reset();
      navigate("/blog");
      return onSave(payload);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAndSetPointOfInterest = async () => {
    const res = await getPointOfInterestsForCampsiteForm();
    if (res.status === 200) {
      const data = res?.data?.response;
      setPointOfInterest(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetPointOfInterest();
  }, []);

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      setValue(key, defaultValues[key]);
    });

    setFormValues(defaultValues, setValue);
  }, [defaultValues, setValue]);

  const handleDescriptionChange = (editorState) => {
    setValue("description", editorState);
  };

  const editorContent = watch("description");

  return (
    <div>
      <form
        className="px-8 space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Blog
              </h3>
              <p className="max-w-2xl mt-1 text-sm text-gray-500">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>

            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Title
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      id="title"
                      name="title"
                      {...register("title")}
                      className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {errors?.title && (
                    <p className="my-3 text-sm text-rose-500">
                      {errors.title.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="altitude"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Total Distance
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="flex max-w-lg rounded-md shadow-sm">
              <input
                type="text"
                id="distance"
                name="distance"
                {...register("distance")}
                className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errors?.distance && (
              <p className="my-3 text-sm text-rose-500">
                {errors.distance.message}
              </p>
            )}
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="altitude"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Total Time
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="flex max-w-lg rounded-md shadow-sm">
              <input
                type="text"
                id="time"
                name="time"
                {...register("time")}
                className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errors?.time && (
              <p className="my-3 text-sm text-rose-500">
                {errors.time.message}
              </p>
            )}
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="altitude"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Altitude
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="flex max-w-lg rounded-md shadow-sm">
              <input
                type="text"
                id="altitude"
                name="altitude"
                {...register("altitude")}
                className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errors?.altitude && (
              <p className="my-3 text-sm text-rose-500">
                {errors.altitude.message}
              </p>
            )}
          </div>
        </div>

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Description
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              <TextEditor
                onChange={handleDescriptionChange}
                value={editorContent}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-x-12">
          <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4   sm:pt-5">
            <label
              htmlFor="trailHead"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Trail Head
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  id="trailHead"
                  name="trailHead"
                  {...register("trailHead")}
                  className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4   sm:pt-5">
            <label
              htmlFor="trailEnd"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Trail End
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  id="trailEnd"
                  name="trailEnd"
                  {...register("trailEnd")}
                  className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4   sm:pt-5">
            <label
              htmlFor="routeType"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Route Type
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  id="routeType"
                  name="routeType"
                  {...register("routeType")}
                  className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4   sm:pt-5">
            <label
              htmlFor="totalAscend"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Total Ascend
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  id="totalAscend"
                  name="totalAscend"
                  {...register("totalAscend")}
                  className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4   sm:pt-5">
            <label
              htmlFor="totalDescend"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Total Descend
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  id="totalDescend"
                  name="totalDescend"
                  {...register("totalDescend")}
                  className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4   sm:pt-5">
            <label
              htmlFor="fitnessLevel"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Fitness Level
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  id="fitnessLevel"
                  name="fitnessLevel"
                  {...register("fitnessLevel")}
                  className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4   sm:pt-5">
            <label
              htmlFor="majorAttraction"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Major Attraction
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  id="majorAttraction"
                  name="majorAttraction"
                  {...register("majorAttraction")}
                  className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4   sm:pt-5">
            <label
              htmlFor="overallTrailGrade"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Overall Trail Grade
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  id="overallTrailGrade"
                  name="overallTrailGrade"
                  {...register("overallTrailGrade")}
                  className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Thumbnail Image
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              {updateBlog ? (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={false}
                  name="thumbnail"
                  updateAction={updateBlog}
                  id={defaultValues.id}
                  onUpload={uploadBlogThumbnail}
                  onDelete={deleteBlogImage}
                />
              ) : (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={false}
                  name="thumbnail"
                  updateAction={updateBlog}
                />
              )}
            </div>
          </div>
        </div>

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Gallery Images
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              {updateBlog ? (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={true}
                  name="gallery"
                  updateAction={updateBlog}
                  id={defaultValues.id}
                  onUpload={uploadBlogGallery}
                  onDelete={deleteBlogImage}
                />
              ) : (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={true}
                  name="gallery"
                  updateAction={updateBlog}
                />
              )}
            </div>
          </div>
        </div>

        {updateBlog && (
          <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
            <label
              htmlFor="blogStatus"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Blog Status
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <select
                  id="blogStatus"
                  name="blogStatus"
                  {...register("blogStatus")}
                  className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option>Choose Option</option>
                  <option value="PUBLISHED">PUBLISHED</option>
                  <option value="DRAFT">DRAFT</option>
                </select>
              </div>
              {errors?.blogStatus && (
                <p className="my-3 text-sm text-rose-500">
                  {errors.blogStatus.message}
                </p>
              )}
            </div>
          </div>
        )}

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Point Of Interest
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="divide-y">
              {pointOfInterests.map((field, index) => {
                return (
                  <div
                    className="flex flex-col w-full gap-2 py-3"
                    key={field.id}
                  >
                    <div className="flex items-center justify-between w-full mb-4">
                      <div className="text-lg font-bold text-gray-400">
                        #{index + 1}
                      </div>

                      <button
                        type="button"
                        className="p-2 rounded-full hover:bg-gray-200 w-max"
                        onClick={() => removePointOfInterests(index)}
                      >
                        <TrashIcon height={22} />
                      </button>
                    </div>

                    <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-4">
                      <input
                        type="number"
                        autoComplete="off"
                        placeholder="Total Kilometers"
                        {...register(`pointOfInterests.${index}.totalKm`, {
                          valueAsNumber: true,
                        })}
                        className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />

                      <input
                        type="number"
                        autoComplete="off"
                        placeholder="Total Hour"
                        {...register(`pointOfInterests.${index}.totalHour`, {
                          valueAsNumber: true,
                        })}
                        className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />

                      <select
                        name="pointOfInterest"
                        autoComplete="off"
                        {...register(
                          `pointOfInterests.${index}.pointOfInterestId`
                        )}
                        className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="">Select Point Of Interest</option>
                        {!!Object.keys(pointOfInterest).length &&
                          pointOfInterest.data.map(({ id, title }) => {
                            return (
                              <option value={id} key={id}>
                                {title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-5 text-xl text-right text-indigo-600 font-small">
              <button
                type="button"
                className="flex items-center text-sm font-light border-b border-indigo-200 hover:bg-gray-200"
                onClick={() => appendPointOfInterests()}
              >
                <Plus className="h-4 pr-1" />
                Add Point Of Interest
              </button>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end gap-x-3">
            <button
              type="button"
              onClick={() => navigate("/point-of-interest")}
              className="px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex justify-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {isSubmitting ? <Spinner /> : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
