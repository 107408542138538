import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { emptyDefaultValues, setFormValues } from "./utils";
import { TextEditor } from "../TextEditor";
import Spinner from "../ui/Spinner";
import { ImageUploadField } from "../ui/ImageUploadField";
import {
  deleteGearImage,
  uploadGearGallery,
  uploadGearThumbnail,
} from "../../services/api/gear";

export const GearForm = ({
  defaultValues = emptyDefaultValues,
  onSave = () => {},
  updateGear = false,
}) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (payload) => {
    try {
      reset();
      navigate("/gears");
      return onSave(payload);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDescriptionChange = (editorState) => {
    setValue("description", editorState);
  };

  const editorContent = watch("description");

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      setValue(key, defaultValues[key]);
    });

    setFormValues(defaultValues, setValue);
  }, [defaultValues, setValue]);

  return (
    <div>
      <form
        className="px-8 space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Gear
              </h3>
              <p className="max-w-2xl mt-1 text-sm text-gray-500">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>

            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      {...register("name")}
                      className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {errors?.name && (
                    <p className="my-3 text-sm text-rose-500">
                      {errors.name.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Description
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              <TextEditor
                onChange={handleDescriptionChange}
                value={editorContent}
              />
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="quantity"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Quantity
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="flex max-w-lg rounded-md shadow-sm">
              <input
                type="text"
                id="quantity"
                name="quantity"
                {...register("quantity")}
                className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errors?.quantity && (
              <p className="my-3 text-sm text-rose-500">
                {errors.quantity.message}
              </p>
            )}
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="realPrice"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Real Price
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="flex max-w-lg rounded-md shadow-sm">
              <input
                type="text"
                id="realPrice"
                name="realPrice"
                {...register("realPrice")}
                className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errors?.realPrice && (
              <p className="my-3 text-sm text-rose-500">
                {errors.realPrice.message}
              </p>
            )}
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="discountedPrice"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Discounted Price
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="flex max-w-lg rounded-md shadow-sm">
              <input
                type="text"
                id="discountedPrice"
                name="discountedPrice"
                {...register("discountedPrice")}
                className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errors?.discountedPrice && (
              <p className="my-3 text-sm text-rose-500">
                {errors.discountedPrice.message}
              </p>
            )}
          </div>
        </div>

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="thumbnail"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Thumbnail Image
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              {updateGear ? (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={false}
                  name="thumbnail"
                  updateAction={updateGear}
                  id={defaultValues.id}
                  onUpload={uploadGearThumbnail}
                  onDelete={deleteGearImage}
                />
              ) : (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={false}
                  name="thumbnail"
                  updateAction={updateGear}
                />
              )}
            </div>
          </div>
        </div>

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Gallery Images
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              {updateGear ? (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={true}
                  name="gallery"
                  updateAction={updateGear}
                  id={defaultValues.id}
                  onUpload={uploadGearGallery}
                  onDelete={deleteGearImage}
                />
              ) : (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={true}
                  name="gallery"
                  updateAction={updateGear}
                />
              )}
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end gap-x-3">
            <button
              type="button"
              onClick={() => navigate("/gear")}
              className="px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex justify-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {isSubmitting ? <Spinner /> : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
