import React, { Fragment, useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import { logoutfetch } from "../features/auth/authFetch";
import { SideBar } from "./SideBar";

const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Log out", href: "#" },
];

export const Layout = () => {
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [viewMode, setViewMode] = useState(); // Default to false (view mode)
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Determine if the page is a blog view page based on the URL pathname
    const isBlogViewPage =
      location.pathname.includes("/blog/") &&
      location.pathname.includes("/view");

    const isBlogEditPage =
      location.pathname.includes("/blog/") &&
      location.pathname.includes("/edit");

    const isPoiViewPage =
      location.pathname.includes("/point-of-interest/") &&
      location.pathname.includes("/view");

    const isPoiEditPage =
      location.pathname.includes("/point-of-interest/") &&
      location.pathname.includes("/edit");

    if (isBlogViewPage) {
      setViewMode(false);
    }

    if (isBlogEditPage) {
      setViewMode(true);
    }

    if (isPoiViewPage) {
      setViewMode(false);
    }

    if (isPoiEditPage) {
      setViewMode(true);
    }
  }, [location.pathname]);

  const logOutHandler = () => dispatch(logoutfetch());

  const toggleViewMode = () => {
    setViewMode((prevMode) => !prevMode);

    // Determine the destination URL based on the current page type
    const pageType = location.pathname.includes("blog")
      ? "blog"
      : "point-of-interest";
    const id = location.pathname.split("/")[2];
    const destination = viewMode ? "view" : "edit";
    const newUrl = `/${pageType}/${id}/${destination}`;

    // Navigate to the destination URL
    navigate(newUrl);
  };

  // Only show the toggle button on the blog view page
  const showToggle =
    (location.pathname.includes("/blog/") &&
      location.pathname.includes("/view")) ||
    (location.pathname.includes("/blog/") &&
      location.pathname.includes("/edit")) ||
    (location.pathname.includes("/point-of-interest/") &&
      location.pathname.includes("/view")) ||
    (location.pathname.includes("/point-of-interest/") &&
      location.pathname.includes("/edit"));

  return (
    <div>
      <SideBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="flex flex-col lg:pl-64">
        <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-white shadow">
          <button
            type="button"
            className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
          </button>

          <div className="flex justify-between flex-1 px-4">
            <div className="flex flex-1">{/*Blank Space*/}</div>
            <div className="flex items-center ml-4 lg:ml-6">
              {/* Toggle button for View/Edit */}
              {showToggle && (
                <>
                  <span className="text-sm font-medium text-gray-900">
                    View
                  </span>
                  {/* Toggle switch for View/Edit */}
                  <label className="inline-flex items-center px-4 cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={viewMode}
                      onChange={toggleViewMode}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                  <span className="text-sm font-medium text-gray-900 pr-6">
                    Edit
                  </span>
                </>
              )}
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="sr-only">Open user menu</span>
                    <span className="flex items-center justify-center w-10 h-10 text-lg font-bold text-gray-600 bg-gray-200 rounded-full hover:bg-gray-300">
                      A
                    </span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map(({ name, href }) => (
                      <Menu.Item key={name}>
                        {name === "Log out" ? (
                          <button
                            type="button"
                            key={name}
                            onClick={logOutHandler}
                            className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-50"
                          >
                            {name}
                          </button>
                        ) : (
                          <a
                            key={name}
                            href={href}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                          >
                            {name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};
